import React from 'react'
import FontAwesome from 'react-fontawesome'

import { formatPrice } from '../../../helpers'
import { Title, IntlText, IntlValue, Ribbon } from '../../../components'
import { ProductTags } from '../../../views'

import './style.css'

export function ProductCardPreview({product, product: {id, name, price, discount, discountedPrice, freeDeliveryAvailable, freeDeliveryFixRate, description, tags, media = {}, disabled}, categoryDisabled}) {

  let { cover, thumbnail } = media || {}
  let _disabled = disabled || categoryDisabled

  return(
    <div id="product-card-preview">
      <div>
        <Title tag="h3"><IntlText id="live-preview">LIVE PREVIEW</IntlText></Title>
        <div className={`card ${_disabled ? "disabled" : ""} ${discount ? "on-sale" : ""} ${freeDeliveryAvailable ? "free-delivery" : ""}`}>
          {freeDeliveryAvailable && <Ribbon><IntlText group="menu-item" id="free-delivery">Free delivery</IntlText></Ribbon>}
          {thumbnail && <div className="product-thumb" style={{backgroundImage: `url(${thumbnail})`}}></div>}
          <div className="menu-item-info">
            {cover && !thumbnail && <div className="product-cover" style={{backgroundImage: `url(${cover})`}}><ProductTags product={product} /></div>}
            <div className="menu-item-title">
              <Title tag="h3"><IntlValue value={name} /></Title>
              <div className="price-box">
                {discount && discountedPrice && <div className="discounted-price"><span>{formatPrice(discountedPrice)}</span></div>}
                <div className="price"><span>{formatPrice(price)}</span></div>
              </div>
            </div>
            <div className="desc"><IntlValue value={description} /></div>
            <div className="tags">
              {!(cover && !thumbnail) && <ProductTags product={product}/>}
            </div>
            {_disabled &&
              <div className="availability"><FontAwesome name="exclamation-triangle" />
              <IntlText group="menu-item" id="not-available">Item is currently not available</IntlText></div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
