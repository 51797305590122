import React, { useState } from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { InvoicesList } from '../../views'
import { Loader, Filter, Wrapper, Filters, DatePicker } from '../../components'
import { useQueryBuilder } from '../../hooks'
import { INVOICES_QUERY } from '../../graphql'

export function Invoices() {

  const location = useLocation()
  const search = new URLSearchParams(location.search)

  const [filter, setFilter] = useState()
  const {addParam, params, removeParam, buildQuery, isQuery, runQuery } = useQueryBuilder({
    data: {
      date: {key: "date", label: `${search.getAll("date")[0]} - ${search.getAll("date")[1]}`, value: search.getAll("date")?.length === 2 ? [search.getAll("date")[0], search.getAll("date")[1]] : undefined, skip: !search.get("date")},
    }
  })
  const {loading, error, data} = useQuery(INVOICES_QUERY, {variables: buildQuery(), skip: !isQuery()})

  function setDate({selection: {startDate, endDate}}){
    const _date = [
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).add(1, 'd').format("YYYY-MM-DD")
    ]
    addParam({date: {key: "date", label: `${_date[0]} - ${_date[1]}`, value: [_date[0], _date[1]], skip: !(_date[0] && _date[1])}})
  }

  const filters = [
    {filter: (data) => data.id},
    {filter: (data) => data.customer.name},
    {filter: (data) => data.email},
    {key: "customer", filter: (data) => data.customer ? data.customer.name || "" : ""},
    {key: "vendor", filter: (data) => data.vendor ? data.vendor.name || "" : ""},
    {key: "status", filter: (data) => data.status.toLowerCase()},
  ]

  if (error) return <Loader theme="main"/>

  return(
    <Wrapper>
      <div id="invoices" className="view">
        <div className="row padding">
          <Filters.Bar>
            <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter("")} placeholder="Type here for searching" />
            <Filters.Dropdown style={{marginLeft: "auto"}} placeholder="Date" value={params?.date?.label} disableInClickClose right reset={() => removeParam("date")} run={runQuery}>
              <DatePicker.Range startDate={params?.date?.value[0] ? new Date(`${params.date.value[0]}T00:00`) : new Date()} endDate={params?.date?.value[1] ? new Date(`${params.date.value[1]}T00:00`) : new Date()} onChange={setDate} />
            </Filters.Dropdown>
          </Filters.Bar>
          <div id="invoices-list" className="relative">
            {(loading) && <Loader theme="main" overlay/>}
            <Filter id="invoices" data={data?.invoiceRequests || []} search={[{key: "search", value: filter}]}  filters={filters} sort={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)} filterKeys={["rider", "status", "vendor", "customer"]}>
              {(data) =>
                <InvoicesList data={data} />
              }
            </Filter>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
