import React, { useState, useRef } from 'react'
import { useQuery } from '@apollo/client'

import { Filters } from '../../../components'
import { RIDERS_QUERY } from '../../../graphql'

export function SelectRider({rider, onSelect, reset, ...rest}){

  const {loading, error, data} = useQuery(RIDERS_QUERY)
  const inlineMenu = useRef(null)
  const [search, setSearch] = useState("")

  if (loading || error) return null

  const { riders } = data
  const selected = riders?.length > 0 ? riders.find(_rider => _rider.id === rider?.value) : undefined

  function onSearch(string){
    setSearch(string)
  }  

  return(
    <Filters.Dropdown {...rest} inlineMenu={inlineMenu} value={selected?.name || ""} placeholder="Rider" reset={reset}>
      <div ref={inlineMenu}><input name="search" autoComplete="off" className='search-input' type="text" value={search} placeholder="Search" onChange={(e) => onSearch(e.target.value)}/></div>
      <ul className="filter-select">
        {riders.filter((rider) => rider?.name && rider?.name?.toLowerCase().search(search.toLowerCase()) !== -1).map((_rider, key) =>
          <li key={key} onClick={() => onSelect(_rider)}><span>{_rider.name}</span></li>
        )}
      </ul>      
    </Filters.Dropdown>
  )

}
