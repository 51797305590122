import React, { useState } from 'react'
import FontAwesome from 'react-fontawesome'

import { formatPrice } from '../../../helpers'
import { IntlText, Button, Actions } from '../../../components'
import { PurchaseAddDiscount } from '../../../views'

import './style.css'

export function PurchaseTotals({children, purchase, purchase: {price, payment, paymentStatus, paymentProblem, sendUtensils, currency}}) {

  const [selected, select] = useState(null)
  const totals = [
    {label: "Service fee for customer", slug: "serviceFeeCustomer", value: (price) => formatPrice(price["serviceFeeCustomer"])},
    {label: "Service fee for vendor", slug: "serviceFeeVendor", value: (price) => formatPrice(price["serviceFeeVendor"])},
    {label: "Delivery fee", slug: "deliveryFeeTotal", value: (price) => formatPrice(price["deliveryFeeTotal"] + price["tax"])},
    {label: "Rider tip", slug: "grossTip", value: (price) => formatPrice(price["grossTip"])},
    {label: "Transaction fee", slug: "transactionFee", value: (price) => formatPrice(price["transactionFee"])},
    {label: "Total revenue", slug: "totalRevenue", value: (price) => formatPrice(price["totalRevenue"])}
  ]

  function _select(_selected){
    if (selected === _selected) return select(undefined)
    select(_selected)
  }
  
  return(
    <div id="purchase-totals">
      <div className="order-notficiations">
        {children}
        <div id="eco" className="totals-info">
          <div className="icon">
            <FontAwesome name="utensils"/>
          </div>
          <div className="info">
            {sendUtensils > 0 ?
              <IntlText group="purchase-totals" id="send-utensils" variables={{number: sendUtensils}} />
              :
              <IntlText group="purchase-totals" id="no-utensils" />
            }
          </div>
        </div>
        {currency !== "MXN" &&
          <div className="totals-info">
            <div className="icon">
              <FontAwesome name="money-bill"/>
            </div>
            <span className="info">
              <IntlText group="purchase-totals" id="currency" variables={{currency: currency, totalInCurrency: `${price.cashChargeInCurrency} ${currency}`, applicableExchangeRate: price.applicableExchangeRate}} />
            </span>
          </div>
        }        
        {payment === "CARD_STRIPE" &&
          <div id="conekta" className="totals-info">
            <div className="icon">
              <FontAwesome name="credit-card"/>
            </div>
            <span className="info">
              <IntlText group="purchase-totals" id="paid-by-credit-card" variables={{status: paymentStatus}} />
            </span>
          </div>
        }
        {paymentProblem && paymentProblem !== "succeeded" &&
          <div id="payment-problem" className="totals-info">
            <div className="icon">
              <FontAwesome name="exclamation-triangle"/>
            </div>
            <span className="info">
              {paymentProblem}
            </span>
          </div>
        }
      </div>
      <table className="totals-table">
        <tbody>
          <tr className="purchase-total subtotal">
            <td><IntlText group="purchase-totals" id="subtotal" /> </td>
            <td>{formatPrice(price["subtotal"])}</td>
          </tr>
          {price["vendorCashBalanceAdjustment"] ?
            <tr className="purchase-total vendorCashBalanceAdjustment">
              <td><IntlText group="purchase-totals" id="vendorCashBalanceAdjustment" /> </td>
              <td>{formatPrice(price["vendorCashBalanceAdjustment"])}</td>
            </tr>
          : null}
          {price["vendorPriceAdjustmentByRiderCashCreditBalance"] ?
            <tr className="purchase-total vendorPriceAdjustmentByRiderCashCreditBalance">
              <td><IntlText group="purchase-totals" id="vendorPriceAdjustmentByRiderCashCreditBalance" /> </td>
              <td>{formatPrice(price["vendorPriceAdjustmentByRiderCashCreditBalance"])}</td>
            </tr>
          : null}
          <tr className="purchase-total vendorCashPayment">
            <td><IntlText group="purchase-totals" id="vendorCashPayment" /> </td>
            <td>{formatPrice(price["vendorCashPayment"])}</td>
          </tr>
          {price["customerCashBalanceAdjustment"] ?
            <tr className="purchase-total customerCashBalanceAdjustment">
              <td><IntlText group="purchase-totals" id="customerCashBalanceAdjustment" /> </td>
              <td>{formatPrice(price["customerCashBalanceAdjustment"])}</td>
            </tr>
          : null}
          {price["discount"] ?
            <tr className="purchase-total discount">
              <td><IntlText group="purchase-totals" id="discount" /> </td>
              <td>{formatPrice(price["discount"])}</td>
            </tr>
          : null}
          <tr className="purchase-total total">
            <td><IntlText group="purchase-totals" id="total" /> </td>
            <td>{payment === "CARD_STRIPE" ? formatPrice(price["chargeOnCard"]) : formatPrice(price["chargeInCash"])}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Actions>
                <Button fluid onClick={() => _select("show-discount")}><IntlText group="purchase-totals" id="add-discount" /></Button>
                <Button fluid onClick={() => _select("show-details")}><IntlText group="purchase-totals" id="show-details" /></Button>
              </Actions>
            </td>
          </tr>
          {selected === "show-details" &&
            totals.map(({slug, label, value, show}) => {
              if (!!price[slug] || show) return(
                <tr key={slug} className={`purchase-total ${slug}`}>
                  <td>{label}</td>
                  <td>{value && value(price)}</td>
                </tr>
              )
              return null
            })          
          }
          {selected === "show-discount" &&
            <tr>
              <td colSpan={2}>          
                <PurchaseAddDiscount purchase={purchase} />
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  )
}