import React, { useState, useRef, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { MODIFY_PURCHASE, CREATE_REFUND_MUTATION } from '../../../graphql'
import { useConfirm, useIntl, useModal } from '../../../hooks'
import { toast } from '../../../helpers'
import { Button, ContextMenu, IntlText, Modal, Modalv3, Title, IntlValue } from '../../../components'
import { PurchaseAddProduct, PurchaseProducts, PurchaseTotals, PurchaseRevisions, PurchaseProduct } from '../../../views'

export function PurchaseCart({purchase, purchase: {products}}) {

  const modal = useRef(null)
  const wrapper = useRef(null)
  const productModal = useModal()
  const revisionModal = useModal()
  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const [edit, toggleEdit] = useState(false)
  const [cart, updateCart] = useState(products)
  const [modfiyPurchase, { loading: modifyLoading }] = useMutation(MODIFY_PURCHASE)
  const [createRefund] = useMutation(CREATE_REFUND_MUTATION)

  useEffect(() => {
    updateCart(products)
  }, [products])  

  function deleteCartItem(key){
    const _cart = [...cart]
    _cart.splice(key, 1)
    updateCart(_cart)
    toggleEdit(true)
  }

  function addItem(_product){
      const _cart = cart.concat([_product])
      updateCart(_cart)
      toggleEdit(true)
  }

  function editItem(_product){
    let _cart = [...cart]
    _cart[_product?.index] = _product
    updateCart(_cart)
    toggleEdit(true)
    productModal.hide()
  }

  function reset(){
      updateCart(products)
      toggleEdit(false)
  }

  async function _modfiyPurchase(){
    try {
        const _products = cart.map((_product) => {
            return({
                product: _product.product.id || _product.product.originalId,
                addons: _product.addons.map((_addon) => ({
                    addon: _addon.addon.id || _addon.addon.originalId,
                    _selectedOptions: _addon.selectedOptions.map((_option) => ({
                        id: _option.id || _option.originalId,
                        quantity: _option.quantity || 1,
                    }))
                })),
                quantity: _product.quantity,
                instructions: _product.instructions,
                forcedPrice: parseFloat(_product.forcedPrice)
            })
        })
      await modfiyPurchase({variables: {id: purchase.id, data: {products: _products}}})
      toggleEdit(false)
      toast.success(getTranslation({id: "edit-success"}))
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) error.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function _createRefund(options){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm()
      alert.update(getTranslation({id: "loading"}))
      const {data: { createRefund: { value }}} = await createRefund(options)
      alert.success(`${value} $ successfully refunded!`)      
    } catch (error) {
      alert.apolloError(error)
    }
  }     

  return(
    <div id="purchase-cart">
      <PurchaseProducts products={cart} vendor={purchase.vendor}>
        {({key, product}) => 
          <ContextMenu.Menu id={purchase?.id} wrapper={wrapper}>
            <ContextMenu.Button onClick={() => productModal.show({index: key, product})} icon="trash"><IntlText id="edit" /></ContextMenu.Button>
            <ContextMenu.Button onClick={() => deleteCartItem(key)} icon="trash"><IntlText id="delete" /></ContextMenu.Button>
            {false &&
              <ContextMenu.Button onClick={() => _createRefund({variables: {data: {purchase: purchase.id, value: product.totalPrice, reason: "REQUESTED_BY_CLIENT", note: "Missing item refund"}}})} icon="undo">Refund item</ContextMenu.Button>
            }
          </ContextMenu.Menu>
        }
      </PurchaseProducts>
      <div style={{marginTop: 15, marginBottom: 15}}>
        <div className='actions'>
          {purchase.revisions?.length > 0 &&
            <Button  onClick={() => revisionModal.show(purchase)}><IntlText group="purchase-cart" id="show-history" /></Button>
          }
          <Button  onClick={() => modal.current?.show()}><IntlText group="purchase-cart" id="add-items" /></Button>
        </div>
        {edit &&
          <div style={{marginTop: 15}} className='actions'>
            <Button  onClick={reset}><IntlText id="reset" /></Button>
            <Button loading={modifyLoading} theme="main" onClick={_modfiyPurchase}><IntlText id="save" /></Button>
          </div>
        }
      </div>
      {!edit &&
        <PurchaseTotals purchase={purchase}>
          {purchase.revisions?.length > 0 &&
            <div id="revisions" className="totals-info">
              <div className="icon">
                <FontAwesome name="exclamation-triangle"/>
              </div>
              <div className="info" onClick={() => revisionModal.show(purchase)}>
                <IntlText group="purchase-totals" id="purchase-history"/>
              </div>
            </div>              
          }
        </PurchaseTotals>        
      }
      <Modal id="purchase-add-product" ref={modal} header={<Title tag="h3"><IntlText group="purchase-cart" id="modal-title" /></Title>}>
        {() =>
          <PurchaseAddProduct purchase={purchase} addItem={addItem} />
        }
      </Modal>      
      <Modalv3 id="purchase-revisions" modal={revisionModal} header={<Title tag="h3"><IntlText group="purchase-revisions" id="modal-title" /></Title>}>
          <PurchaseRevisions purchase={revisionModal.state} />        
      </Modalv3>      
      <Modalv3 id="purchase-product" contentStyle={{overflow: "hidden"}} modal={productModal} header={<Title tag="h3"><IntlValue>{productModal.state?.product?.product?.name}</IntlValue></Title>}>
        <PurchaseProduct index={productModal.state?.index} {...productModal.state?.product} vendor={purchase.vendor} addItem={editItem} />
      </Modalv3>           
    </div>
  )
}