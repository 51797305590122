import React from 'react'

import { Theme } from './components'
import { ModalProvider, IntlProvider } from './contexts'

import { Router } from './router'

function App() {
  return (
    <IntlProvider>
      <ModalProvider>
        <Theme>
          <Router />
        </Theme>
      </ModalProvider>
    </IntlProvider>
  )
}

export default App
