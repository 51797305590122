import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { Wrapper, Sidebar, SidebarMenu, IntlText } from '../../components'
import { AlertBarSettings, InfoModalSettings, DeliveryZones, SettingsList, OperationSettings, PaymentSettings } from '../../views'

export function Settings() {


  return(
    <Wrapper>
      <div id="configuration" className="container">
        <div className="row padding">
          <Sidebar.View>
            <Sidebar.Menu size={1} style={{maxWidth: 320}}>
              <SidebarMenu.Menu bordered>
                <SidebarMenu.Link to="" exact="true"><IntlText group="settings" id="tab-menu-operation" /></SidebarMenu.Link>
                <SidebarMenu.Link to="alert-bar"><IntlText group="settings" id="tab-menu-alert-bar" /></SidebarMenu.Link>
                <SidebarMenu.Link to="info-modal"><IntlText group="settings" id="tab-menu-info-modal" /></SidebarMenu.Link>
                <SidebarMenu.Link to="payment"><IntlText group="settings" id="tab-menu-payment" /></SidebarMenu.Link>
                <SidebarMenu.Link to="delivery"><IntlText group="settings" id="tab-menu-delivery" /></SidebarMenu.Link>
                <SidebarMenu.Link to="settings"><IntlText group="settings" id="tab-menu-settings" /></SidebarMenu.Link>
              </SidebarMenu.Menu>
            </Sidebar.Menu>
            <Sidebar.Content size={2}>
              <Routes>
                <Route path="" element={<OperationSettings />} />
                <Route path="operation" element={<OperationSettings />} />
                <Route path="alert-bar" element={<AlertBarSettings />} />
                <Route path="info-modal" element={<InfoModalSettings />} />
                <Route path="delivery" element={<DeliveryZones />} />
                <Route path="payment" element={<PaymentSettings />} />
                <Route path="settings" element={<SettingsList />} />
              </Routes>
            </Sidebar.Content>
          </Sidebar.View>
        </div>
      </div>
    </Wrapper>
  )
}
