import React, { useState, useContext } from 'react'
import moment from 'moment'

import { IntlContext } from '../../contexts'
import { DatePicker, Filters, IntlText } from '../../components'
import { SelectRider } from '../../views'

const statuses = [
  {id: "COMPLETED"},
  {id: "CANCELLED"},
  {id: "REJECTED"},
  {id: "DELIVERYFAILED"},
  {id: "UNPROCESSED"},
  {id: "PAYMENTFAILED"},
  {id: "REQUIRES_PAYMENT"},
]

const sorts = [
  {id: "CREATEDAT_ASCENDING"},
  {id: "CREATEDAT_DESCENDING"},
]

const payments = [
  {id: "CASH"},
  {id: "CARD_STRIPE"},
]

export function HistoryFilter({params, addParam, removeParam, runQuery, updateQuery, loading}) {

  const [search, setSearch] = useState(params?.query?.value)
  const { getTranslation } = useContext(IntlContext)

  function onSearch(e){
    if (e.key !== 'Enter') return false
    updateQuery({query: {value: search, id: "search", skip: !search}}) 
  }

  function setDate({selection: {startDate, endDate}}){
    const _date = [
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).add(1, 'd').format("YYYY-MM-DD")
    ]
    addParam({date: {key: "date", value: [_date[0], _date[1]], label: `${_date[0]} - ${_date[1]}`, skip: !(_date[0] && _date[1])}})
  }

  function resetInput(){
    removeParam("query")
    setSearch(null)
  }

  return(
    <div id="history-filter">
      <Filters.Bar>
        <Filters.Input loading={loading} id="query" name="query" value={search} onChange={({target}) => setSearch(target?.value)} onKeyDown={onSearch} reset={resetInput} placeholder={getTranslation({group: "history-filter", id: "search"})} />
        <Filters.Button style={{marginLeft: "auto"}} id="new" active={!!params?.onlyModified?.value} reset={() => removeParam("onlyModified")} onClick={() => updateQuery({onlyModified: {value: !params?.onlyModified?.value, id: "onlyModified", label: "Only modified"}})}><IntlText group="history-filter" id="only-modified" /></Filters.Button>
        <Filters.Button id="new" active={!!params?.isReferral?.value} reset={() => removeParam("isReferral")} onClick={() => updateQuery({isReferral: {value: !params?.isReferral?.value, id: "isReferral", label: "Only referrals"}})}><IntlText group="history-filter" id="isReferral" /></Filters.Button>
        <Filters.Select value={params?.sort?.value ? getTranslation({group: "history-filter", id: params?.sort?.value}): null} placeholder={getTranslation({group: "history-filter", id: "sort"})} reset={() => removeParam("sort")}>
          {sorts.map((_status, key) =>
            <Filters.SelectItem key={key} active={params?.sort?.value === _status.id} onClick={() => updateQuery({sort: {value: _status.id, id: "sort", label: _status.id}})}>
              <span>{getTranslation({group: "history-filter", id: _status.id})}</span>
            </Filters.SelectItem>            
          )}
        </Filters.Select>
        <SelectRider rider={params?.rider} reset={() => removeParam("rider")} onSelect={(_rider) => updateQuery({rider: {value: _rider?.id, id: "rider", label: _rider?.name, skip: !_rider?.id}})}/>
        <Filters.Select value={params?.status?.value ? getTranslation({group: "history-filter", id: params?.status?.value}) : undefined} placeholder={getTranslation({group: "history-filter", id: "status"})} reset={() => removeParam("status")}>
          {statuses.map((_status, key) =>
            <li key={key} onClick={() => updateQuery({status: {key: "status", value: _status?.id, skip: !_status?.id}})}><span><IntlText group="history-filter" id={_status.id} /></span></li>
          )}
        </Filters.Select>
        <Filters.Select value={params?.payment?.value ? getTranslation({group: "history-filter", id: params?.payment?.value}) : undefined} placeholder={getTranslation({group: "history-filter", id: "payment"})} reset={() => removeParam("payment")}>
          {payments.map((_payment, key) =>
            <li  key={key} onClick={() => updateQuery({payment: {key: "payment", value: _payment.id}})}><span><IntlText group="history-filter" id={_payment.id} /></span></li>
          )}
        </Filters.Select>
        <Filters.Dropdown placeholder={getTranslation({group: "history-filter", id: "date"})} value={params?.date?.label} disableInClickClose right run={runQuery} reset={() => removeParam("date")}>
          <DatePicker.Range startDate={params?.date?.value[0] ? new Date(`${params.date.value[0]}T00:00`) : new Date()} endDate={params?.date?.value[1] ? new Date(`${params.date.value[1]}T00:00`) : new Date()} onChange={setDate} />
        </Filters.Dropdown>
      </Filters.Bar>
    </div>
  )

}
