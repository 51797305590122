import React from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import FontAwesome from 'react-fontawesome'
import { useMutation } from '@apollo/client'

import { formatPrice } from '../../helpers'
import { useValidatedForm } from '../../hooks'
import { CREATE_REFUND_MUTATION } from '../../graphql'
import { Button, IntlText, Loader, LabeledInput, Tooltip, LabeledTextarea, Select, StatusDot } from '../../components'

import './OrderRefunds.css'

export default function OrderRefunds({purchase, purchase: {refunds, price}}) {

  const reasons = [
    {id: "INCOMPLETE_ORDER", reason: "REQUESTED_BY_CLIENT", label: "Incomplete order", disablePriceEdit: false, disableVendorDiscountableEdit: false, note: "Incomplete order partial refund", price: 0, discountableFromVendor: 0},
    {id: "REJECTED", label: "Rejected", reason: "CANNOT_BE_FULFILLED", disablePriceEdit: true, disableVendorDiscountableEdit: true, note: "Order rejected by tomato.mx", price: price.chargeOnCard, discountableFromVendor: price.vendorPrice},
    {id: "UNPROCESSED", reason: "CANNOT_BE_FULFILLED", label: "Unprocessed", disablePriceEdit: true, disableVendorDiscountableEdit: true, note: "Unprocessed order", price: price.chargeOnCard, discountableFromVendor: price.vendorPrice},
    {id: "REQUESTED_BY_CLIENT", reason: "REQUESTED_BY_CLIENT", label: "Requested by the client", disablePriceEdit: true, disableVendorDiscountableEdit: true, note: "Order cancelled by the client", price: price.chargeOnCard, discountableFromVendor: price.vendorPrice},
    {id: "DUPLICATED_TRANSACTION", reason: "DUPLICATED_TRANSACTION", label: "Duplicated transaction", disablePriceEdit: true, disableVendorDiscountableEdit: true, note: "Duplicated order", price: price.chargeOnCard, discountableFromVendor: price.vendorPrice},
    {id: "SUSPECTED_FRAUD", reason: "SUSPECTED_FRAUD", label: "It's a fraud", disablePriceEdit: true, disableVendorDiscountableEdit: true, note: "Fraudalent behavior or banned account", price: price.chargeOnCard, discountableFromVendor: price.vendorPrice},
  ]

  const { form: {amount, discountableFromVendor, note, reason}, updateField, updateFields } = useValidatedForm({}, {})
  const [createRefund, { loading }] = useMutation(CREATE_REFUND_MUTATION)

  async function _createRefund(options){
    if (!amount || !reason || !reason.reason || !note) return toast.error(<IntlText group="order-refunds" id="form-error">Please check the form</IntlText>)

    try {
      let {data: { createRefund: { value }}} = await createRefund(options)
      toast.success(`${value} $ successfully refunded!`)
      updateField({key: "amount", value: undefined})
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  function _setReason(_reason){
    updateFields({fields: [{key: "amount", value: _reason?.price}, {key: "discountableFromVendor", value: _reason?.discountableFromVendor}, {key: "note", value: _reason?.note}, {key: "reason", value: _reason}]})
  }

  function getRefundStatus(refund){
    switch (refund.status) {
      case 'REFUNDED':
        return true
      case 'FAILED':
        return false
      default:
        return false
    }
  }

  return(
    <div id="order-refunds">
      {loading && <Loader theme="main" overlay/>}
      <div className="order-refunds">
        {refunds && refunds.length > 0 &&
          <table className="table ProductsListrefunds-list">
            <thead>
              <tr>
                <th></th>
                <th><IntlText group="order-refunds" id="table-header-amount">Amount</IntlText></th>
                <th><IntlText group="order-refunds" id="table-header-discontable">Discountable from vendor</IntlText></th>
                <th><IntlText group="order-refunds" id="table-header-reason">Reason</IntlText></th>
                <th><IntlText group="order-refunds" id="table-header-note">Note</IntlText></th>
                <th><IntlText group="order-refunds" id="table-header-createdAt">Date</IntlText></th>
              </tr>
            </thead>
            <tbody>
              {refunds.map((refund, key) =>
                <tr className="refund-list-item" key={key}>
                  <td className="status"><Tooltip text={refund.statusMessage}><StatusDot active={getRefundStatus(refund)} /></Tooltip></td>
                  <td className="amount">{formatPrice(refund.value)}</td>
                  <td className="amount">{formatPrice(refund.discountableFromVendor)}</td>
                  <td className="reason"><IntlText group="order-refunds" id={refund.reason}>{refund.reason}</IntlText></td>
                  <td className="amount">{refund.note}</td>
                  <td className="createdAt">{moment(refund.createdAt).format('MM/DD/YYYY HH:mm')}</td>
                </tr>
              )}
            </tbody>
          </table>
        }
        <div className="new-refund">
          <Select placeholder="Reason" selected={reason} defaultValue="Please select" options={reasons} onChange={_setReason} />
          <LabeledInput type="number" disabled={reason?.disablePriceEdit} placeholder="Refund amount" value={amount} onChange={(e) => updateField({key: "amount", value: parseFloat(e.target.value)})} />
          <LabeledInput type="number" disabled={reason?.disableVendorDiscountableEdit} placeholder="Discountable from vendor" value={discountableFromVendor} onChange={(e) => updateField({key: "discountableFromVendor", value: parseFloat(e.target.value)})} />
          <LabeledTextarea name="note" placeholder="Refund note" value={note} onChange={(e) => updateField({key: "note", value: parseFloat(e.target.value)})} />
          <Button theme="main" onClick={() => _createRefund({variables: {data: {purchase: purchase.id, value: amount, discountableFromVendor, reason: reason?.reason, note: note}}})}><FontAwesome name="undo" /><IntlText group="order-refunds" id="refund-button">Refund this amount</IntlText></Button>
        </div>
      </div>
    </div>
  )
}
