import React, { useState } from 'react'
import FontAwesome from 'react-fontawesome'
import { toast } from 'react-toastify'

import config from '../../../config'
import { Map, IntlText, Button, Form, EditBox } from '../../../components'
import { useValidatedForm } from '../../../hooks'

export function VendorLocation({pickupInformation, location, onChange, loading}) {

  const [edit, toggleEdit] = useState(false)
  const { form: {coordinates}, updateField } = useValidatedForm({
    coordinates: location?.coordinates || [config.defaultCenter.lng, config.defaultCenter.lat]
  })

  function changeLocation(e){
    if (!edit) return false

    updateField({key: "coordinates", value: [e.lng, e.lat]})
  }

  function save(){
    if (!coordinates) return toast.error(<IntlText group="vendor-location" id="no-location-selected" />)

    onChange({location: {type: "Point", coordinates: coordinates}})
    toggleEdit(false)
  }

  function savePickupInformation(value){
    onChange({pickupInformation: value})
  }

  return(
    <div id="vendor-location">
      <Form.Form>
        <Form.Field>
          <div className="map" style={{height: 400}}>
            {edit ?
              <Map.Actions>
                <Button onClick={() => toggleEdit(false)}><IntlText id="cancel" /></Button>
                <Button theme="main" loading={loading} onClick={save}><IntlText id="save" /></Button>
              </Map.Actions>
            : !edit ?
              <Map.Actions>
                <Button onClick={() => toggleEdit(true)}><IntlText id="edit" /></Button>
              </Map.Actions>
            : null}
            <Map.Map center={{lng: coordinates[0], lat: coordinates[1]}} onClick={(e) => changeLocation(e)}>
              <Map.Pin lng={coordinates[0]} lat={coordinates[1]}><FontAwesome name="map-marker" /></Map.Pin>
            </Map.Map>
          </div>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<div><FontAwesome name="map-marker" /><IntlText group="vendor-location" id="pickup-information" /></div>} save={(value) => savePickupInformation(value)}>{pickupInformation}</EditBox.Text>
        </Form.Field>
      </Form.Form>
    </div>
  )
}
