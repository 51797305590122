import React, { useContext } from 'react'
import moment from 'moment'
import { useMutation, useQuery } from '@apollo/client'
import { useParams} from 'react-router-dom'

import { toast } from '../../helpers'
import { RIDER_PAYOUT, EDIT_RIDER_PAYOUT, CREATE_CFDI_OF_RIDER_PAYOUT, UPLOAD_RIDER_PAYOUT_INVOICE } from '../../graphql'
import { IntlContext } from '../../contexts'
import { Wrapper, EditBox, Form, IntlText, Loader, ContextMenu, Title } from '../../components'
import { StatusNotification, VerifiedDocument } from '../../views'

export function RiderPayout(){
  const { payoutID } = useParams()
  const { loading, data, error } = useQuery(RIDER_PAYOUT, {variables: {id: payoutID}})

  if (loading || error) return <Loader theme="main" />

  return(
    <Wrapper loading={loading || error}>
      <div id="rider-payouts" className="container">
        <div className="row padding">       
          <div id="rider-payouts">
            <Inner riderPayout={data?.riderPayout} />
          </div>
        </div>
      </div>
    </Wrapper>
  )

}

function Inner({riderPayout}){

  const { getTranslation } = useContext(IntlContext)
  const [edit] = useMutation(EDIT_RIDER_PAYOUT)
  const [createCFDI] = useMutation(CREATE_CFDI_OF_RIDER_PAYOUT)
  const [uploadRiderPayoutInvoice] = useMutation(UPLOAD_RIDER_PAYOUT_INVOICE)

  async function _createCFDI(){
    const { success, error } = toast.loading()

    try {
      await createCFDI({variables: {id: riderPayout?.id}})
      success(getTranslation({id: "create-success"}))
    } catch (e) {
      error(e.message)
      e.graphQLErrors?.map(x => error(x.message))
    }    
  }
  
  async function _edit(data){
    const { success, error } = toast.loading()

    try {
      await edit({variables: {id: riderPayout?.id, ...data}})
      success(getTranslation({id: "edit-success"}))
    } catch (e) {
      error(e.message)
      e.graphQLErrors?.map(x => error(x.message))
    }    
  }

  async function _uploadRiderPayoutInvoice({target}){
    const { success, error } = toast.loading()

    try {
      if (!target.files) return toast.error("There is nothing to upload")

      const  file = target.files[0]
      await uploadRiderPayoutInvoice({variables: {file, id: riderPayout.id}})
      return success(getTranslation({id: "edit-success"}))
    } catch (e) {
      error(e.message)
      e.graphQLErrors?.map(x => error(x.message))
    }
  }  

  function getStatus(){
    switch (riderPayout.status) {
      case "PAID":
        return "success"
      case "CLOSED_WITHOUT_PAYMENT":
        return "error"
      case "WAITING_FOR_INVOICE":
      case "INVOICE_IN_PROCESS":
      case "TRANSFER_IN_PROCESS":
      default:
        return "warning"
    }
  }

  function getIcon(){
    switch (riderPayout.status) {
      case "PAID":
        return "check"        
      case "CLOSED_WITHOUT_PAYMENT":
        return "times"
      case "WAITING_FOR_INVOICE":
      case "INVOICE_IN_PROCESS":
      case "TRANSFER_IN_PROCESS":        
      default:
        return "hourglass"
    }    
  }

  const statusOptions = {
    TRANSFER_IN_PROCESS: {icon: "check", theme: 'valid', value: "ACCEPTED"},
    PAID: {icon: "check", theme: 'valid', value: "ACCEPTED"},
    CLOSED_WITHOUT_PAYMENT: {icon: "times", theme: 'invalid', value: "NOT_REQUIRED"},
    DEFAULT: {icon: "clock", theme: 'warning', value: "PENDING"},
  }  

  return(
    <Form.Form>
      <Form.Field>
        <StatusNotification title={<IntlText id={riderPayout?.status} />} icon={getIcon()} type={getStatus()} message={riderPayout.statusMessage} editMessage={(_val) => _edit({statusMessage: _val})} actions={[
            <ContextMenu.Button key="accepted" className='valid' onClick={() => _edit({status: "PAID"})} icon="check"><IntlText id="PAID" /></ContextMenu.Button>,
            <ContextMenu.Button key="inreview" className='warning' onClick={() => _edit({status: "WAITING_FOR_INVOICE"})} icon="clock"><IntlText id="WAITING_FOR_INVOICE" /></ContextMenu.Button>,
            <ContextMenu.Button key="invoice-in-process" className='warning' onClick={() => _edit({status: "INVOICE_IN_PROCESS"})} icon="clock"><IntlText id="INVOICE_IN_PROCESS" /></ContextMenu.Button>,
            <ContextMenu.Button key="transfer-in-process" className='warning' onClick={() => _edit({status: "TRANSFER_IN_PROCESS"})} icon="clock"><IntlText id="TRANSFER_IN_PROCESS" /></ContextMenu.Button>,
            <ContextMenu.Button key="rejected" className='invalid' onClick={() => _edit({status: "CLOSED_WITHOUT_PAYMENT"})} icon="ban"><IntlText id="CLOSED_WITHOUT_PAYMENT" /></ContextMenu.Button>
        ]}>
          <span><IntlText group="rider-payouts" id={`${riderPayout?.status}-info`} /></span>
        </StatusNotification>            
      </Form.Field>
      <Form.Fieldset highlighted>
        <Title tag='h3'><IntlText id="details" /></Title>
        <Form.Field>
          <EditBox.Text placeholder={<div><IntlText group="rider-payouts" id="fullName" /></div>}>{riderPayout.rider.fullName}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<div><IntlText group="rider-payouts" id="rider" /></div>}>{riderPayout?.rider?.username}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<div><IntlText group="rider-payouts" id="reference" /></div>}>{riderPayout.reference}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<div><IntlText group="rider-payouts" id="grossInvoiceAmount" /></div>}>{riderPayout.grossInvoiceAmount}</EditBox.Text>
        </Form.Field>
      </Form.Fieldset>
      <Title tag='h3'><IntlText id="additional-details" /></Title>
      <Form.Field>
        <EditBox.Text placeholder={<div><IntlText group="rider-payouts" id="netInvoiceAmount" /></div>}>{riderPayout.netInvoiceAmount}</EditBox.Text>
      </Form.Field>
      <Form.Field>
        <EditBox.Text placeholder={<div><IntlText group="rider-payouts" id="tax" /></div>}>{riderPayout.tax}</EditBox.Text>
      </Form.Field>
      <Form.Field>
        <EditBox.Text placeholder={<div><IntlText group="rider-payouts" id="periodicity" /></div>}>{riderPayout.periodicity}</EditBox.Text>
      </Form.Field>
      {riderPayout.invoiceDueAt &&
        <Form.Field>
          <EditBox.Text placeholder={<div><IntlText group="rider-payouts" id="invoiceDueAt" /></div>}>{moment(riderPayout.invoiceDueAt).format("YYYY MMMM DD")}</EditBox.Text>
        </Form.Field>
      }
      {riderPayout.invoiceUploadedAt && 
        <Form.Field>
          <EditBox.Text placeholder={<div><IntlText group="rider-payouts" id="invoiceUploadedAt" /></div>}>{moment(riderPayout.invoiceUploadedAt).format("YYYY MMMM DD")}</EditBox.Text>
        </Form.Field>
      } 
      <Form.Field>
        <VerifiedDocument name={getTranslation({group: "rider-payouts", id:"invoice"})} status={riderPayout?.status} statusOptions={statusOptions} url={riderPayout?.invoice} upload={_uploadRiderPayoutInvoice}>
          <ContextMenu.Button onClick={_createCFDI} icon="link"><IntlText group="rider-payouts" id="create-invoice" /></ContextMenu.Button>
        </VerifiedDocument>
      </Form.Field>        
    </Form.Form>         
  )
}
