import React from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { EDIT_VENDOR_MUTATION, DELETE_VENDOR_MUTATION } from '../../../graphql'
import { useConfirm, useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { EditBox, Button, Form } from '../../../components'

export function VendorReset({vendor}) {

  const navigate = useNavigate()
  const { getTranslation } = useIntl()
  const { confirm } = useConfirm() 
  const [edit] = useMutation(EDIT_VENDOR_MUTATION)
  const [deleteVendor] = useMutation(DELETE_VENDOR_MUTATION)  

  async function _reset(_value){

    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      if (!_value) throw new Error(getTranslation({id: "empty-error"}))

      await confirm({title: getTranslation({group: "customer-reset", id: "confirm"}), desc: getTranslation({group: "customer-reset", id: "confirm-info", variables: {name: vendor.name}})})
      alert.update(getTranslation({id: "loading"}))
      await edit({variables: {id: vendor.id, data: {password: _value}}})
      alert.success(getTranslation({id: "reset-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }

  async function deleteUser(){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await deleteVendor({variables: {id: vendor.id}})
      navigate('/vendors')
      alert.success(getTranslation({id: "delete-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }

  return(
    <div id="vendor-reset">
      <Form.Form>
        <Form.Field>
          <EditBox.Text text="*******" placeholder="Reset account password" save={_reset} />
        </Form.Field>
        <Form.Field>
          <Button theme="reject" className="full" onClick={deleteUser}>Delete user</Button>
        </Form.Field>
      </Form.Form>
    </div>
  )

}
