import React from 'react'
import moment from 'moment'

import { toTitle } from '../../../helpers'
import { ContextMenu, Table } from '../../../components'

export function InvoicesList({data}) {
  return(
    <Table.Table>
      <Table.Head>
        <Table.Tr>
          <Table.Th className="text-left">Customer</Table.Th>
          <Table.Th className="text-left">Vendor</Table.Th>
          <Table.Th>Amount</Table.Th>
          <Table.Th>Created at</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th></Table.Th>
        </Table.Tr>
      </Table.Head>
      <Table.Body>
        {data.length > 0 ? data.map((invoice, key) =>
          <Table.Tr key={key} className="invoice">
            <Table.Td className="text-left">{invoice.customer.name}</Table.Td>
            <Table.Td className="text-left">{invoice.vendor.name}</Table.Td>
            <Table.Td>{invoice.amount}</Table.Td>
            <Table.Td>{moment(invoice.createdAt).format("MM/DD/YYYY HH:mm")}</Table.Td>
            <Table.Td>{toTitle(invoice.status)}</Table.Td>
            <Table.Td>
            <ContextMenu.Menu id={invoice?.id}>
              <ContextMenu.Link to={`/customers/invoices/${invoice.id}`} icon="external-link-alt">View invoice</ContextMenu.Link>
            </ContextMenu.Menu>
            </Table.Td>
          </Table.Tr>
        ) :
          <Table.Tr>
            <Table.Td colSpan={6}>There aren't any items to show, try to search</Table.Td>
          </Table.Tr>
        }
      </Table.Body>
    </Table.Table>
  )
}
