import React from 'react'
import FontAwesome from 'react-fontawesome'

import { IntlText } from '../../components'

import './style.css'

export function NotFound({text}) {
  return(
    <div id="not-found" className="container">
      <div className="row padding">
        <div id="not-found-wrapper">
          <FontAwesome name="exclamation-triangle"/>
          <p className="error-text">{text ? text : <IntlText group="not-found" id="message" />}</p>
        </div>
      </div>
    </div>
  )
}
