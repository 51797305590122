import { gql } from '@apollo/client'

import { PURCHASE_PRICE_FRAGMENT, PURCHASE_CUSTOMER_FRAGMENT, PURCHASE_ADDRESS_FRAGMENT, PURCHASES_VENDOR_FRAGMENT } from './'
import { REFUND_FRAGMENT } from '../fragments'

export const PURCHASES_FRAGMENT = gql`
  fragment PURCHASES_FRAGMENT on Purchase {
    id
    slug
    customerNote
    customer{
      ...PURCHASE_CUSTOMER_FRAGMENT
    }
    address{
      ...PURCHASE_ADDRESS_FRAGMENT
    }
    vendor{
      ...PURCHASES_VENDOR_FRAGMENT
    }
    price{
      ...PURCHASE_PRICE_FRAGMENT
    }
    refunds{
      ...REFUND_FRAGMENT
    }
    notes{
      id
      note
      ownerName
    }
    rider{
      originalId
      color
      whatsappGroup
      name
      slug
      type
      phone{
        countryCode
        phoneCode
        phoneNumber
      }
      cel{
        countryCode
        phoneCode
        phoneNumber
      }
    }
    riderConfirmed
    arrivedAtVendor
    status
    payment
    paymentStatus
    paymentProblem
    conektaPaymentId
    mercadoPagoPaymentId
    currency
    sendUtensils
    preparationTime
    completedIn
    voided
    banknote
    problem
    group
    isMobileApp
    timestamps{
      paymentProcessed
      processed
      vendorConfirmed
      assignedToRider
      riderConfirmed
      readyForCollection
      arrivedAtVendor
      collected
      arrivedAtClient
      completed
      deliveryFailed
      cancelled
      rejected
      unprocessed
      paymentFailed      
      queued
      flaggedForConfirmation
    }
    reportTimestamps{
      vendorIsNotReady
      clientDoesNotRepond
      riderDidNotArrive
    }
    type
    requiresConfirmation
    autoProcessNotApplicable    
    createdAt
    updatedAt
  }
  ${PURCHASE_CUSTOMER_FRAGMENT}
  ${PURCHASE_ADDRESS_FRAGMENT}
  ${PURCHASES_VENDOR_FRAGMENT}
  ${PURCHASE_PRICE_FRAGMENT}
  ${REFUND_FRAGMENT}
`
