import React from 'react'
import { toast } from 'react-toastify'
import { useMutation, useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { formatPrice } from '../../../helpers'
import { useValidatedForm } from '../../../hooks'
import { Loader, LabeledInput, LabeledTextarea, Button, Validation, Select, IntlText, Form } from '../../../components'
import { PURCHASE_QUERY, CREATE_RIDER_MODIFICATION_MUTATION } from '../../../graphql'

import './style.css'

const validations = {
  value: [{id: "min0", validation: (val) => !!(val > 0)}],
  reason: [{id: "required", validation: (val) => !!(val && val.id)}],
}

const reasons = [
  {id: "INCOMPLETE_ORDER", label: <div><IntlText>Incomplete order</IntlText><div className="info">The purchase had a missing item</div></div>},
  {id: "ITEM_ADDED", label: <div><IntlText>Item added</IntlText><div className="info">There was an item added to the purchase</div></div>},
  {id: "CLIENT_CREDIT", label: <div><IntlText>Client credit</IntlText><div className="info">Customer had credit that was discounted from this order</div></div>},
  {id: "CLIENT_DEBIT", label: <div><IntlText>Client debit</IntlText><div className="info">Customer had debit that was charged on this order</div></div>},
  {id: "CLIENT_DISCOUNT", label: <div><IntlText>Discount</IntlText><div className="info">There was a discount applied on the order</div></div>},
  {id: "FAILED_DELIVERY", label: <div><IntlText>Failed delivery</IntlText><div className="info">Purchase was cancelled</div></div>},
  {id: "OTHER_CREDIT", label: <div><IntlText>Other credit</IntlText><div className="info">Other costs, expenses, losses for the rider (discounted from him)</div></div>},
  {id: "OTHER_DEBIT", label: <div><IntlText>Other debit</IntlText><div className="info">Other collections (collected from rider)</div></div>},
  {id: "OTHER", label: <div><IntlText>Other</IntlText><div className="info">Any other modification not listed here</div></div>}
]

export function PurchaseModifications({purchase: purchaseID, cancel}){

  const { loading, data, error } = useQuery(PURCHASE_QUERY, {variables: {id: purchaseID}, fetchPolicy: "network-only"})
  const [createRiderModification, { loading: createLoading }] = useMutation(CREATE_RIDER_MODIFICATION_MUTATION)
  const { form: {reason, note, value}, updateField, updateFields, errors, validate, resetForm } = useValidatedForm({}, validations)

  const { purchase } = data || {}

  async function _createRiderModification(){

    let { valid } = validate()

    if (!valid) return toast.error("The are errors in the form, please check it.")

    try {
      await createRiderModification({variables: {data: {purchase: purchaseID, rider: purchase.rider.originalId, note, value: parseInt(value), reason: reason.id}}})
      resetForm()
      cancel()
      return toast.success("Note added successfully")
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) e.graphQLErrors.map(x => toast.error(x.message))
      console.log("error", e)

    }
  }

  function changeReason(type){
    if (type.id === "FAILED_DELIVERY"){
      updateFields({fields: [{key: "reason", value: type}, {key: "value", value: purchase?.price?.vendorPrice}]})
    } else {
      updateField({key: "reason", value: type})
    }
  }

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  return(
    <div id="purchase-modifications">
      <div className="help">Add new or edit exisisting modifications</div>
      <div className="purchase-modifications">
        <Loader loading={createLoading} theme="main" overlay />
        {purchase.riderModifications && purchase.riderModifications.map((riderModification, key) =>
          <div className={`purchase-modification ${riderModification.status.toLowerCase()}`} key={key}>
            <div className="info">
              <div className="status"><FontAwesome name="circle" /></div>
              <div className="note">{riderModification.note}</div>
              <div className="price-wrapper">{formatPrice(riderModification.value)}</div>
            </div>
          </div>
        )}
      </div>
      <Form.Form>
        <Form.Field>
          <Validation errors={errors.reason}>
            <Select placeholder="Problem" selected={reason} defaultValue="Please select" options={reasons} onChange={changeReason} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.value}>
            <LabeledInput placeholder="Value" value={value} onChange={(e) => updateField({key: "value", value: e.target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.note}>
            <LabeledTextarea placeholder="Write your note here" onChange={(e) => updateField({key: "note", value: e.target.value})}>{note}</LabeledTextarea>
          </Validation>
        </Form.Field>
        <Form.Fieldset>
          <Form.Field fluid>
            <Button className="fluid" onClick={cancel}>Cancel</Button>
          </Form.Field>
          <Form.Field fluid>
            <Button className="fluid" theme="main" loading={createLoading} onClick={() => _createRiderModification()}>Add</Button>
          </Form.Field>
        </Form.Fieldset>
      </Form.Form>
    </div>
  )

}
