import React from 'react'

import { useModal } from '../../../hooks'
import { IntlText, Notificaion, ContextMenu } from '../../../components'
import { MessageModal } from '../'

export function StatusNotification({title, icon, type, children, actions, message, editMessage}){
  
  const modal = useModal()
  const _actions = editMessage ? actions.concat(<ContextMenu.Button key='edit-message' onClick={() => modal.show()} icon="comment"><IntlText group="verified-document" id="edit-message"  /></ContextMenu.Button>) : actions

  return(
    <>
      <Notificaion title={title} icon={icon} type={type} actions={
        <ContextMenu.Menu>
          {_actions}
        </ContextMenu.Menu>
      } >
        {children}
      </Notificaion>    
      <MessageModal modal={modal} message={message} edit={editMessage} />
    </>
  )
}
