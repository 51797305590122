import React, { useRef } from 'react'
import moment from 'moment'

import { StatusDot, Table, ContextMenu, Loader, Modal, Title, IntlText, Filterv2, Badge, Tooltip } from '../../../components'
import { StartRiderWorkshift } from '../../../views'

export function RidersList({data, loading, refetch, filter}) {

  const modal = useRef()
  
  function sendMessage(cel){
    const phone = (cel?.phoneCode + cel?.phoneNumber)?.replace(/[\s+()]/g, "")
    window.open(`whatsapp://send/?phone=${phone}`)
  }

  return(
    <div id="riders-list">
      {(loading) && <Loader theme="main" overlay/>}
      {data.length > 0 &&  
        <Filterv2 data={data || []} filters={[
          {filter: (data) => (!!data?.name && data.name.toLowerCase().search(filter?.toLowerCase()) !== -1) ||( !!data?.username && data.username.toLowerCase().search(filter?.toLowerCase()) !== -1), skip: !filter},
        ]} >
          {(data) =>
            <Table.Table>
              <Table.Head>
                <Table.Tr>
                  <Table.Th></Table.Th>
                  <Table.Th className="text-left"><IntlText group="riders" id="name" /></Table.Th>
                  <Table.Th className="text-left"><IntlText group="riders" id="username" /></Table.Th>
                  <Table.Th className="text-left"><IntlText group="riders" id="email" /></Table.Th>
                  <Table.Th className="text-left"><IntlText group="riders" id="phone" /></Table.Th>
                  <Table.Th className="text-left"><IntlText group="riders" id="type" /></Table.Th>
                  <Table.Th><IntlText group="riders" id="rating" /></Table.Th>
                  <Table.Th><IntlText group="riders" id="tags" /></Table.Th>
                  <Table.Th className="text-left"><IntlText group="riders" id="createdAt" /></Table.Th>
                  <Table.Th className="text-left"><IntlText group="riders" id="lastPurchaseDelivered" /></Table.Th>
                </Table.Tr>
              </Table.Head>
              <Table.Body>
                {data.map((rider, key) =>
                  <Table.Tr key={key} className={["rider", rider.active && rider.available ? null : "disabled"].join(" ")}>
                    <Table.Td style={{width: 20}}><StatusDot active={rider.active} /></Table.Td>
                    <Table.Td className="text-left">
                      <div style={{display: "flex", alignItems: "center", gap: 10}}>
                        {rider.name}
                        <ContextMenu.Menu id={rider?.id}>
                          <ContextMenu.Link to={`/riders/${rider.id}`} icon="external-link-alt">Open</ContextMenu.Link>
                          {!rider.active && !rider.available &&
                            <ContextMenu.Button onClick={() => modal.current?.show({rider})} icon="play">Start workshift</ContextMenu.Button>
                          }
                        </ContextMenu.Menu>                      
                      </div>
                    </Table.Td>
                    <Table.Td className="text-left">{rider.username}</Table.Td>
                    <Table.Td className="text-left">{rider.email}</Table.Td>
                    <Table.Td className="text-left">{rider.cel && <div  onClick={() => sendMessage(rider.cel)}><i className="fab fa-whatsapp-square" />{`+${rider.cel?.phoneCode} ${rider.cel?.phoneNumber}`}</div>}</Table.Td>
                    <Table.Td className="text-left"><IntlText group="riders" id={rider.type} /></Table.Td>
                    <Table.Td><span style={{color: rider.percentageOfPositive < 0.5 ? "var(--invalid-color)" : rider.percentageOfPositive < 0.8 ? "var(--warning-color)" : "var(--valid-color)"}}>{Math.round(rider.percentageOfPositive * 100)}%</span></Table.Td>
                    <Table.Td className="text-left">
                      {rider.banned &&
                        <Tooltip text={rider.bannedNote}>
                          <Badge theme='invalid'>Banned</Badge>                      
                        </Tooltip>
                      }
                      {rider.suspended &&
                        <Tooltip text={rider.suspendMessage}>
                          <Badge theme='invalid'>Suspended</Badge>                      
                        </Tooltip>
                      }
                      {rider.profileStatus === "APPROVED" &&
                        <Badge theme='valid'>Approved</Badge>                      
                      }
                    </Table.Td>
                    <Table.Td className="text-left">{moment(rider.createdAt).format("YYYY-MM-DD")}</Table.Td>
                    <Table.Td className="text-left">{rider.lastPurchaseDelivered && `${moment().diff(rider.lastPurchaseDelivered, "days")} days ago`}</Table.Td>
                  </Table.Tr>
                )}
              </Table.Body>
            </Table.Table>
          }
        </Filterv2>
      }
      <Modal ref={modal} header={<Title tag="h3"><IntlText group="start-rider-workshift" id="modal-title" /></Title>} >
        {({state}) =>
          <StartRiderWorkshift loading={loading} data={state} cancel={() => modal.current?.hide()} refetch={refetch}/>
        }
      </Modal>         
    </div>
  )
}


