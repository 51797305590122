import React from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { Loader, HourPicker, Form, SwitchBox, IntlText } from '../../../components'
import { SETTINGS_QUERY, EDIT_SETTING_MUTATION } from '../../../graphql'
import { extractSettings } from '../../../helpers'
import { useIntl } from '../../../hooks'

export function OperationSettings() {

  const { getTranslation } = useIntl()
  const { loading, data, error } = useQuery(SETTINGS_QUERY, {variables: {keys: ["internalBreak", "largeInflow", "rainbreak", "holidayBreak", "QUEUEISCLOSED", "OPENING_HOUR_FROM", "OPENING_HOUR_TO", "AUTO_PROCESS_ENABLED", "REQUIRES_RIDER_CONFIRM_TO_PROCESS"]}})
  const [_editSetting] = useMutation(EDIT_SETTING_MUTATION)

  async function editSetting(key, value){
    try {
      await _editSetting({variables: {key, data: {value}}})
    } catch (e) {
      console.log("error: ", e);
    }
  }

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  let settings = extractSettings(data.settings)

  return(
    <div id="operation-settings">
      <Form.Form>
        <Form.Field>
          <SwitchBox id="largeinflow" label={getTranslation({group: "settings", id: "largeinflow-info"})} checked={settings["largeinflow"]?.value} onChange={({target}) => editSetting(settings["largeinflow"]?.key, target.checked.toString())}>
            <IntlText group='settings' id="largeinflow" />
          </SwitchBox>       
        </Form.Field>
        <Form.Field>
          <SwitchBox id="queueisclosed" label={getTranslation({group: "settings", id: "queueisclosed-info"})} checked={settings["queueisclosed"]?.value} onChange={({target}) => editSetting(settings["queueisclosed"]?.key, target.checked.toString())}>
            <IntlText group='settings' id="queueisclosed" />
          </SwitchBox>               
        </Form.Field>        
        <Form.Field>
          <SwitchBox id="requires_rider_confirm_to_process" label={getTranslation({group: "settings", id: "requires_rider_confirm_to_process-info"})} checked={settings["requires_rider_confirm_to_process"]?.value} onChange={({target}) => editSetting(settings["requires_rider_confirm_to_process"]?.key, target.checked.toString())}>
            <IntlText group='settings' id="requires_rider_confirm_to_process" />
          </SwitchBox>               
        </Form.Field>              
        <Form.Field>
          <SwitchBox id="auto_process_enabled" label={getTranslation({group: "settings", id: "auto_process_enabled-info"})} checked={settings["auto_process_enabled"]?.value} onChange={({target}) => editSetting(settings["auto_process_enabled"]?.key, target.checked.toString())}>
            <IntlText group='settings' id="auto_process_enabled" />
          </SwitchBox>               
        </Form.Field>          
        <Form.Field>
          <SwitchBox id="rainbreak" label={getTranslation({group: "settings", id: "rainbreak-info"})} checked={settings["rainbreak"]?.value} onChange={({target}) => editSetting(settings["rainbreak"]?.key, target.checked.toString())}>
            <IntlText group='settings' id="rainbreak" />
          </SwitchBox>               
        </Form.Field>
        <Form.Field>
          <SwitchBox id="internalbreak" label={getTranslation({group: "settings", id: "internalbreak-info"})} checked={settings["internalbreak"]?.value} onChange={({target}) => editSetting(settings["internalbreak"]?.key, target.checked.toString())}>
            <IntlText group='settings' id="internalbreak" />
          </SwitchBox>               
        </Form.Field>
        <Form.Field>
          <SwitchBox id="holidaybreak" label={getTranslation({group: "settings", id: "holidaybreak-info"})} checked={settings["holidaybreak"]?.value} onChange={({target}) => editSetting(settings["holidaybreak"]?.key, target.checked.toString())}>
            <IntlText group='settings' id="holidaybreak" />
          </SwitchBox>  
        </Form.Field>
        <Form.Fieldset>
          <Form.Field>
            <HourPicker placeholder="Open at" time={settings["opening_hour_from"]?.value.toString()} onChange={(data) => editSetting(settings["opening_hour_from"]?.key, data.hours.toString() + data.mins.toString())}/>
          </Form.Field>
          <Form.Field>
            <HourPicker placeholder="Close at" time={settings["opening_hour_to"]?.value.toString()} onChange={(data) => editSetting(settings["opening_hour_to"]?.key, data.hours.toString() + data.mins.toString())}/>
          </Form.Field>
        </Form.Fieldset>
      </Form.Form>
    </div>
  )
}
