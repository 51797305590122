import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, ComposedChart, Line } from 'recharts'

import { useIntl } from '../../../hooks'

import './style.css'

export function DashboardDiagrams({dailyStats}) {
  
  const { getTranslation } = useIntl()

  function purchasesTotals(data){
    return (
      <div>
        <span className="placeholder">Total failed</span><span className='value'>{data.totals?.totalOrdersFailed}</span>
      </div>
    )
  }

  function calculatePurchaseTtals(acc, curr){
    acc.totalOrdersFailed = (acc.totalOrdersFailed || 0) + curr.unprocessedPurchases + curr.cancelledPurchases + curr.rejectedPurchases +curr.failedDeliveries
    
    return acc
  }

  return ( 
      <div id="dashboard-charts">
        <div className="chartbox">
          <Chart name={getTranslation({group: "dashboard", id: "completedPurchases"})} title="Daily orders" desc="The number of the daily orders" valueKey="date" value="completedPurchases" data={dailyStats} additionalBars={["voidedPurchases", "failedDeliveries", "unprocessedPurchases", "cancelledPurchases", "rejectedPurchases"]} moreTotals={purchasesTotals} totals={calculatePurchaseTtals}>
            {() =>
              <>
                <Bar name={getTranslation({group: "dashboard", id: "voided-purchases"})} label={false} dataKey="voidedPurchases" stackId="a" fill="grey" />
                <Bar name={getTranslation({group: "dashboard", id: "failed-deliveries"})} label={false} dataKey="failedDeliveries" stackId="a" fill="var(--invalid-color)" />
                <Bar name={getTranslation({group: "dashboard", id: "unprocessed-purchases"})} label={false} dataKey="unprocessedPurchases" stackId="a" fill="var(--invalid-color)" />
                <Bar name={getTranslation({group: "dashboard", id: "cancelled-purchases"})} label={false} dataKey="cancelledPurchases" stackId="a" fill="var(--invalid-color)" />
                <Bar name={getTranslation({group: "dashboard", id: "rejected-purchases"})} label={false} dataKey="rejectedPurchases" stackId="a" fill="var(--invalid-color)" />            
              </>
            }
          </Chart>
          <Chart name={getTranslation({group: "dashboard", id: "referralPurchasesCompleted"})} title="Referral purchases" desc="The number of the completed purchases by referred customers" valueKey="date" value="referralPurchasesCompleted" data={dailyStats} />
        </div>
        <div className="chartbox">
          <Chart name={getTranslation({group: "dashboard", id: "referralRewardPaid"})} title="Referral reward paid" desc="The amount paid for referral orders" valueKey="date" value="referralRewardPaid" data={dailyStats} />
          <LineChart title="Avg basket size" desc="The average size of the purchased items" valueKey="date" value="avgBasketSize" data={dailyStats} />
          <Chart name={getTranslation({group: "dashboard", id: "signUps"})} title="Daily registrations" desc="The number of the new customer registrations by day" valueKey="date" value="signUps" data={dailyStats} />
          <Chart name={getTranslation({group: "dashboard", id: "referralSignUps"})} title="Referral signups" desc="Users signed up with referral code" valueKey="date" value="referralSignUps" data={dailyStats} />
          <LineChart title="Number of referrers" desc="The total number of referrer users" valueKey="date" value="numberOfReferrers" data={dailyStats} />
          <Chart name={getTranslation({group: "dashboard", id: "failedPayments"})} title="Failed payments" desc="The number of the failed online payment attempts by day" valueKey="date" value="failedPayments" data={dailyStats} barColor="var(--invalid-color)" />
          <LineChart title="Number of vendors" desc="The total number of registered vendors" valueKey="date" value="numberOfVendors" data={dailyStats} />
          <LineChart title="Number of vendor users" desc="The total number of vendor users" valueKey="date" value="numberOfVendorUsers" data={dailyStats} />
          <LineChart title="Vendor outstandings" desc="The total vendor outstandings" valueKey="date" value="currentVendorCashReceivables" data={dailyStats} barColor="var(--valid-color)" />
          <LineChart title="Vendor outstandings" desc="The total vendor outstandings" valueKey="date" value="currentVendorCashReceivables" data={dailyStats} barColor="var(--valid-color)" />
          <LineChart title="Vendor liabilities" desc="The total vendor liabilities" valueKey="date" value="currentVendorCashLiabilities" data={dailyStats} barColor="var(--invalid-color)" />
          <LineChart title="Customer outstandings" desc="The total customer outstandings" valueKey="date" value="currentCustomerCashReceivables" data={dailyStats} barColor="var(--valid-color)" />
          <LineChart title="Customer liabilities" desc="The total customer liabilities" valueKey="date" value="currentCustomerCashLiabilities" data={dailyStats} barColor="var(--invalid-color)" />
        </div>
    </div>   
  )
}

function Chart({name, title, desc, valueKey, value, data, barColor = "var(--valid-color)", children, additionalBars, totals, moreTotals}){

  const { getTranslation } = useIntl()

  if (!data?.length > 0) return null

  const _data = data.reduce((accumulator, currentValue) => {
    const _total = accumulator.total + currentValue[value]
    const _additionalBars = additionalBars?.length > 0 ? additionalBars.reduce((acc, current) => { 
      acc[current] = currentValue[current]
      return acc
    }, {}) : {}

    const _data = accumulator.data.concat({
      key: currentValue[valueKey],
      value: currentValue[value],
      ..._additionalBars
    })

    return {data: _data, total: _total, totals: totals ? totals(accumulator.totals, currentValue) : null}

  }, {data: [], total: 0, totals: {}});  

  const valueAccessor = attribute => ({ payload }) => {
    if (!(payload[attribute] > 0)) return null

    return payload[attribute]
  }  

  return(
    <div className='chart'>
      <div className="chart-title">
        <div className='name'>
          <h3>{title}</h3>
          <span className="note">{desc}</span>
        </div>
        <div className='total'>
          <div><span className="placeholder">Total</span><span className='value'>{_data.total}</span></div>
          {moreTotals && moreTotals(_data)}
        </div>
      </div>
      <ResponsiveContainer width="99%" height={300} style={{background: "var(--ui-element)", borderRadius: 5}}>
          <BarChart data={_data.data}>
          <CartesianGrid strokeDasharray="3 3" stroke="var(--ui-placeholder)" />
          <XAxis dataKey="key" />
          <YAxis label={{ value: getTranslation({group: "dashboard", id: value}), angle: -90, position: 'center', dx: -30}} />
          <Tooltip cursor={{fill: 'var(--ui-bg)', opacity: 0.5}} contentStyle={{background: "var(--ui-element)", border: 0, borderRadius: 5, padding: 20}} />
          {children && children()}
          <Bar dataKey="value" maxBarSize={100} stackId="a" fill={barColor} radius={[10, 10, 0, 0]} name={name}>
            <LabelList valueAccessor={valueAccessor("value")} style={{fill: "white", fontWeight: "bold"}} />                  
          </Bar>
          </BarChart>
      </ResponsiveContainer>               
    </div>
  )
}

function LineChart({title, desc, valueKey, value, data, barColor = "var(--ui-placeholder)", unit}){

  const { getTranslation } = useIntl()

  if (!data?.length > 0) return null

  return(
    <div className='chart'>
      <div className="chart-title">
        <div className='name'>
          <h3>{title}</h3>
          <span className="note">{desc}</span>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300} style={{background: "var(--ui-element)", borderRadius: 5}}>
        <ComposedChart data={data}>
          <CartesianGrid strokeDasharray="3 3" stroke="var(--ui-placeholder)" />
          <XAxis dataKey={valueKey} />
          <YAxis type='number' label={{ value: getTranslation({group: "dashboard", id: value}), angle: -90, position: 'center', dx: -30 }} />
          <Tooltip cursor={{fill: 'var(--ui-bg)', opacity: 0.5}} contentStyle={{background: "var(--ui-element)", border: 0, borderRadius: 5, padding: 20}} />
          <Line strokeWidth={2} type="monotone" dataKey={value} name={getTranslation({group: "dashboard", id: value})} unit={unit} stroke={barColor} />
        </ComposedChart>
      </ResponsiveContainer>          
    </div>
  )
}
