import React, { useContext, useRef, useState } from 'react'
import FontAwesome from 'react-fontawesome'
import { useQuery } from '@apollo/client'
import Tippy from '@tippyjs/react'

import { formatPrice } from '../../../helpers'
import { useQueryBuilder } from '../../../hooks'
import { QUERY_PRODUCTS } from '../../../graphql'
import { Filters, IntlText, IntlValue, Loader, Modal, Title } from '../../../components'
import { PurchaseProduct } from '../../../views'
import { IntlContext } from '../../../contexts'

export function PurchaseAddProduct({purchase, addItem}) {

  const modal = useRef(null)
  const [value, setValue] = useState(null)
  const [selectedProduct, selectProduct] = useState(undefined)
  const { getTranslation } = useContext(IntlContext)
  const { params, removeParam, isQuery, buildQuery, runQuery } = useQueryBuilder()  
  const { data, loading  } = useQuery(QUERY_PRODUCTS, {variables: {...buildQuery(), vendor: purchase.vendor.originalId, first: 20,}, skip: !isQuery(), notifyOnNetworkStatusChange: true})

  function onSearch(e){
    if (e.key !== 'Enter') return false
    runQuery({instantParams: {...params, query: {value: value, id: "search", label: `Search for ${value}`, skip: !value}}})
  }

  function onChange({target}){
    setValue(target.value)

    if (!target.value) removeParam("query")
  }

  function _reset(){
    setValue("")
    removeParam("query")
  }

  function _selectProduct(_product){
    selectProduct(_product)
    modal.current?.show()
  }
  
  function _addItem(_product){
    addItem(_product)
    modal.current?.hide()
  }
  
  return(
    <div className="puchase-add-product">
      <Filters.Bar style={{marginBottom: 0}}>
        <Filters.Input id="query" name="query" icon="search" value={value} onChange={onChange} placeholder={getTranslation({group: "puchase-add-product", id: "search-input-products"})} onKeyDown={onSearch} reset={_reset}/>
      </Filters.Bar>
      <div className='purchase-products relative'>
        {loading && <Loader theme='main' />}
        {data?.queryProducts?.edges.map(({node: product}, key) => 
          <div key={key} className="purchase-product" onClick={() => !product.product?.disabled ? _selectProduct(product) : null}>
            <div className="product-name-box">
              <div className="product-name">
                <span className="name">
                    <IntlValue>{product.product.name}</IntlValue>
                </span>
                <span className="category">
                  <Tippy content={<IntlText group="purchase-products" id="category-name" />}>
                    <div><FontAwesome name="list-alt" /><IntlValue>{product.category.name}</IntlValue></div>
                  </Tippy>
                </span>
                {(product.product?.disabled || product.product?.hidden) &&
                  <span className="disabled"><IntlText group="purchase-products" id="disabled" /></span>
                }
              </div>
            </div>
            <div className="product-price-box">
                <span className="price">{formatPrice(product.product.price)}</span>
            </div>
          </div>            
        )}
      </div>
      <Modal id="purchase-product" contentStyle={{overflow: "hidden"}} ref={modal} header={<Title tag="h3"><IntlValue>{selectedProduct?.product?.name}</IntlValue></Title>}>
        {() => 
          <PurchaseProduct {...selectedProduct} vendor={purchase.vendor} addItem={_addItem} />
        }
      </Modal>      
    </div>
  )
}

