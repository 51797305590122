import React from 'react'
import moment from 'moment'
import { useMutation } from '@apollo/client'

import { useValidatedForm, useModal, useIntl, useConfirm } from '../../../hooks'
import { toast } from '../../../helpers'
import { START_RIDER_WORKSHIFT } from '../../../graphql'
import { IntlText, Button, Form, Validation, LabeledInput, HourPicker, LabeledTextarea, LabeledCheckbox, DatePicker, Title, Modalv3 } from '../../../components'

const validations = {
  cash: [{id: "min0", validation: (val) => !!(val >= 0)}]
}

export function StartRiderWorkshift({data, cancel, refetch}) {

  const modal = useModal()
  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const [startWorkshift, { loading }] = useMutation(START_RIDER_WORKSHIFT, {onCompleted: () => {
    refetch()
  }})
  const { form: { cash, date, time, note, setToAvailable }, updateField, errors, validate, resetForm } = useValidatedForm({
    date: moment().format("YYYY-MM-DD"),
    time: moment().format("HHmm"),
    cash: 1000,
    setToAvailable: true
  }, validations)
  
  async function _startWorkshift(){

    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      const { valid } = validate()

      if (!valid) throw new Error(getTranslation({id: "form-error"}))

      await confirm({title: getTranslation({group: "start-rider-workshift", id: "confirm"}), desc: getTranslation({group: "start-rider-workshift", id: "confirm-info", variables: {amount: cash, name: data?.rider?.name, time: moment(time, "HHmm").format("HH:mm")}})})
      alert.update(getTranslation({id: "loading"}))
      await startWorkshift({variables: {
        rider: data?.rider?.id, 
        cash: parseInt(cash), 
        time: moment(`${date} ${time}`, "YYYY-MM-DD HHmm").format("YYYY-MM-DD HH:mm"),
        setToAvailable, 
        note
      }})
      resetForm()
      cancel()
      refetch()
      alert.success(getTranslation({group: "start-rider-workshift", id: "start-success", variables: {name: data?.rider?.name}}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  function setDate(obj){
    updateField({key: "date", value: moment(obj).format("YYYY-MM-DD")})
    modal.hide()
  }
  
  return(
    <>
      <Form.Form>
        <Form.Field>
          <Validation errors={errors.cash}>
            <LabeledInput type='number' placeholder={getTranslation({group: "start-rider-workshift", id: "cash"})} value={cash} onChange={(e) => updateField({key: "cash", value: e.target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <LabeledTextarea placeholder={getTranslation({group: "start-rider-workshift", id: "note"})} value={note} onChange={(e) => updateField({key: "note", value: e.target.value})} />
        </Form.Field>
        <Form.Fieldset style={{alignItems: "stretch"}}>
          <Form.Field>
            <Button style={{height: "100%", fontSize: "var(--font-m)"}} onClick={() => modal.show()}>{date}</Button>
          </Form.Field>
          <Form.Field style={{justifyContent: "center", display: "flex"}}>
            <HourPicker minsStep={5} time={time} onChange={(data) => updateField({key: "time", value: `${data.hours}${data.mins}`})}/>
          </Form.Field>
        </Form.Fieldset>
        <Form.Field>
          <LabeledCheckbox id="setToAvailable" name="setToAvailable" placeholder={getTranslation({group: "start-rider-workshift", id: "set-to-active"})} checked={setToAvailable} onChange={({target}) => updateField({key: target.name, value: target.checked})} rounded/>
        </Form.Field>        
        <Form.Fieldset>
          <Form.Field fluid>
            <Button className="fluid" onClick={cancel}><IntlText id="cancel" /></Button>
          </Form.Field>
          <Form.Field fluid>
            <Button className="fluid" theme="main" loading={loading} onClick={_startWorkshift}><IntlText group="start-rider-workshift" id="start" /></Button>
          </Form.Field>
        </Form.Fieldset>
      </Form.Form>
      <Modalv3 id="select-date" contentStyle={{overflow: "hidden"}} modal={modal} header={<Title tag="h3"><IntlText group="select-date" id="modal-title" /></Title>}>
        <DatePicker.Date fluid date={date ? new Date(`${date}T00:00`) : new Date()} onChange={setDate} />
      </Modalv3>   
    </>
  )
}
