import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Routes, Route } from 'react-router-dom'

import { PRODUCT_QUERY } from '../../graphql'
import { IntlText, Loader, Wrapper, Sidebar, SidebarMenu } from '../../components'
import { ModalContext } from '../../contexts'
import { ProductMedia, ProductDetails, ProductAddons, ProductTagEditor, ProductCardPreview, ProductStatus, NotFound, AddProductAddonOptionModal, AddProductAddonModal } from '../../views'

export function Product() {

  const { id } = useParams()
  const { closeModal } = useContext(ModalContext)
  const {loading, error, data} = useQuery(PRODUCT_QUERY, {variables: {id}})

  if (loading) return <Loader theme="main" />
  if (error) return `Error: ${error}`

  const { product } = data

  if (!product) return(
    <>
      <div id="product" className="container">
        <NotFound />
      </div>
    </>
  )

  return(
    <Wrapper>
      <div id="product" className="container">
        <div className="row padding">
          <ProductCardPreview product={product} />
          <Sidebar.View>
            <Sidebar.Menu size={1}>
              <SidebarMenu.Menu bordered>
                <SidebarMenu.Link to="" exact={true} append={5}><IntlText group="product" id="details" /></SidebarMenu.Link>
                <SidebarMenu.Link to="availibility" append={5}><IntlText group="product" id="availibility" /></SidebarMenu.Link>
                <SidebarMenu.Link to="addons" append={5}><IntlText group="product" id="addons" /></SidebarMenu.Link>
                <SidebarMenu.Link to="tags" append={5}><IntlText group="product" id="tags" /></SidebarMenu.Link>
                <SidebarMenu.Link to="media" append={5}><IntlText group="product" id="media" /></SidebarMenu.Link>
              </SidebarMenu.Menu>
            </Sidebar.Menu>
            <Sidebar.Content size={3}>
              <Routes>
                <Route path="" element={<ProductDetails product={product} onSuccess={() => closeModal("product")}/>} />
                <Route path="availibility" element={<ProductStatus product={product} />} />
                <Route path="addons" element={<ProductAddons product={product}/>} />
                <Route path="media" element={<ProductMedia product={product} />} />
                <Route path="tags" element={<ProductTagEditor product={product} />} />
              </Routes>
            </Sidebar.Content>
          </Sidebar.View>
        </div>
      </div>
      <AddProductAddonModal product={product}  />
      <AddProductAddonOptionModal product={product} />
    </Wrapper>
  )

}
