import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import moment from 'moment'
import FontAwesome from 'react-fontawesome'
import Tippy from '@tippyjs/react'

import { useModal, useAwakeDetection } from '../../../hooks'
import { toast } from '../../../helpers'
import { SEND_RIDER_TO_BREAK, CHANGE_BREAK_STATUS, REQUEST_DAILY_BREAK, RIDERS_QUERY, RIDER_BREAKS, RIDER_STATUS_CHANGED_SUBSCRIPTION, RIDER_BREAK_UPDATED, NEW_RIDER_BREAK, PURCHASES_QUERY, MY_PURCHASES_UPDATED_SUBSCRIPTION } from '../../../graphql'
import { Loader, Modalv3, Title, IntlText, ContextMenu, Input } from '../../../components'

export function ActiveRiders({runQuery}){
  
    const modal = useModal()
    const { data, loading, error, subscribeToMore, refetch } = useQuery(RIDERS_QUERY, {variables: {active: true}})
    
    useAwakeDetection({callback: refetch, minutes: 1})
    useEffect(() => {
      const unsub = subscribeToMore({
        document: RIDER_STATUS_CHANGED_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev
          const _riders = [...prev?.riders]
          const index = _riders.findIndex((purchase) => purchase.id === subscriptionData.data.riderStatusChange.id)
          if (index >= 0){
            _riders[index] = subscriptionData.data.riderStatusChange
            return Object.assign({}, prev, {
              riders: _riders
            })
          } else {
            return Object.assign({}, prev, {
              riders: [subscriptionData.data.riderStatusChange, ..._riders]
            })
          }
        }
      })

      try {
        refetch()
      } catch (error) {
        console.log("Refetch error", error)
      }

      return () => {
        unsub()
      }

    // eslint-disable-next-line
    }, [])  
  
    if (loading || error) return <Loader />
  
    const  { riders } = data
    const allRiders = riders?.length || 0
    const activeRiders = riders?.filter(_rider => _rider.available && _rider.active)?.length || 0  
  
    return(
      <>
        <div className="btn" onClick={() => modal.show()}>
          <FontAwesome name="motorcycle" />
          <span className="label">Available riders</span>
          <span className="value">{activeRiders}/{allRiders}</span>          
        </div>
        <ActiveRidersModal modal={modal} riders={riders} runQuery={runQuery} />
      </>
    )
  }

  function ActiveRidersModal({modal, riders, runQuery}){
  
    const [search, setSearch] = useState(null)
    const purchases = useQuery(PURCHASES_QUERY, {variables: {date: moment().format("YYYY-MM-DD"), status: ["INQUEUE", "PROCESSED", "INPREPARATION", "READYFORCOLLECTION", "ONTHEWAY", "ATLOCATION"]}, notifyOnNetworkStatusChange: false})
    const { data, loading, error, subscribeToMore } = useQuery(RIDER_BREAKS, {variables: {date: moment().format("YYYY-MM-DD")}})
    const [sendRiderToBreak] = useMutation(SEND_RIDER_TO_BREAK)
    const [changeBreakStatus] = useMutation(CHANGE_BREAK_STATUS)
    const [requestDailyBreak] = useMutation(REQUEST_DAILY_BREAK)  
  
    useEffect(() => {
      const myPurchasesUpdated = purchases.subscribeToMore({
          document: MY_PURCHASES_UPDATED_SUBSCRIPTION,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev
            const _data = Array.from(prev?.purchases || [])
            const index = _data.findIndex((purchase) => purchase.id === subscriptionData.data.myPurchasesUpdated.id)
            if (index >= 0){
              if (["CANCELLED", "DELIVERYFAILED", "REJECTED", "UNPROCESSED", "COMPLETED"].includes(_data[index].status)){
                _data.splice(index, 1)
              } else {
                _data[index] = subscriptionData.data.myPurchasesUpdated
              }
              return Object.assign({}, prev, {
                  purchases: _data
              })
            } else {
              return Object.assign({}, prev, {
                purchases: [..._data, subscriptionData.data.myPurchasesUpdated]
              })              
            }
        }})

        const riderBreakUpdated = subscribeToMore({
            document: RIDER_BREAK_UPDATED,
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev
                const _riderBreaks = [...prev?.riderBreaks]
                const index = _riderBreaks.findIndex((purchase) => purchase.id === subscriptionData.data.riderBreakUpdated.id)
                if (index >= 0){
                    _riderBreaks[index] = subscriptionData.data.riderBreakUpdated
                    return Object.assign({}, prev, {
                        riderBreaks: _riderBreaks
                    })
                } else {
                    return Object.assign({}, prev, {
                        riderBreaks: [subscriptionData.data.riderBreakUpdated, ..._riderBreaks]
                    })
                }
            }
        })
    
        const newRiderBreak = subscribeToMore({
            document: NEW_RIDER_BREAK,
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev
                const _riderBreaks = [...prev?.riderBreaks]
                const index = _riderBreaks.findIndex((purchase) => purchase.id === subscriptionData.data.newRiderBreak.id)
                if (index >= 0){
                    _riderBreaks[index] = subscriptionData.data.newRiderBreak
                    return Object.assign({}, prev, {
                        riderBreaks: _riderBreaks
                    })
                } else {
                    return Object.assign({}, prev, {
                        riderBreaks: [subscriptionData.data.newRiderBreak, ..._riderBreaks]
                    })
                }
            }
        })

        return () => {
          myPurchasesUpdated()
          riderBreakUpdated()
          newRiderBreak()
        }
    // eslint-disable-next-line
    }, [])  
  
    function onSelect({rider}){
      runQuery({instantParams: {rider: {value: rider?.id, id: "rider", label: rider?.name}}})
      setSearch("")
      modal.hide()
    }
  
    async function _changeBreakStatus(_data){
      try {
        await changeBreakStatus({variables: _data})
      } catch (e) {
        toast.error(e?.message)
      }
    }
  
    async function _sendRiderToBreak(_data){
      try {
        await sendRiderToBreak({variables: _data})
      } catch (e) {
        toast.error(e?.message)
      }
    }
  
    async function _requestDailyBreak(id){
      try {
        await requestDailyBreak({variables: {rider: id}})
      } catch (e) {
        toast.error(e?.message)
      }
    }
  
    function switchIcon(_riderDailyBreak){  
      switch (_riderDailyBreak?.status) {
        case "FINISHED":
          return <div><Tippy content={`Break finished at ${moment(_riderDailyBreak.end).format("HH:mm")} (${moment.duration(moment(_riderDailyBreak.end).diff(_riderDailyBreak.start)).asMinutes().toFixed(0)} min)`}><div><FontAwesome className="requested-icon finished" name="utensils" /></div></Tippy></div>
        case "ONBREAK":
          return <div><Tippy content={`On break since ${moment.duration(moment().diff(_riderDailyBreak.start)).asMinutes().toFixed(0)} min`}><div><FontAwesome className="requested-icon onbreak" name="utensils" /></div></Tippy></div>
        case "INTRANSFER_TO_BREAK":
          return <div><Tippy content={`Waiting for daily break since ${moment(_riderDailyBreak.createdAt).format("HH:mm")}`}><div><FontAwesome className="requested-icon onbreak" name="utensils" /></div></Tippy></div>
        case "REQUESTED":
          return <div><Tippy content={`Break requested at ${moment(_riderDailyBreak.createdAt).format("HH:mm")}`}><div><FontAwesome className="requested-icon requested" name="utensils" /></div></Tippy></div>
        default:
            return null
  
      }
    }  
  
    if (loading || error || purchases.loading || purchases.error) return <Loader />
  
    const counts = purchases.data?.purchases?.reduce((current, next) => {
        if (!next?.rider?.slug) return current
    
        current[next?.rider?.slug] = (current[next?.rider?.slug] || 0) + 1
    
        return current
    
    }, {})    
    const { riderBreaks } = data
  
    return(
      <Modalv3 modal={modal} id="available-riders" header={<Title tag="h3"><IntlText group="active-riders" id="title" /></Title>} >
        <Input value={search} onChange={({target}) => setSearch(target.value)} reset={() => setSearch(null)} placeholder="Start searching" />
        <div className='rider-buttons'>
          {[...riders].filter(elem => elem.name?.toString()?.toLowerCase().search(search?.toLowerCase()) !== -1).sort((a,b) => a.name?.localeCompare(b.name)).map((rider, key) => {
            const _riderDailyBreak = riderBreaks?.find(_break => _break.rider.originalId === rider?.id && _break.type === "DAILYBREAK")
            const _activeBreak = riderBreaks?.find(_break => _break.rider.originalId === rider?.id && (_break.status === "ONBREAK" || _break.status === "INTRANSFER_TO_BREAK"))
              
            return(
              <div key={key} className="rider-button" >
                <div className='label' onClick={() => onSelect({rider})}>
                  {_activeBreak?.id && _activeBreak.status === "ONBREAK" ?
                    <Tippy content={`Went to break (${_activeBreak.type}) at ${moment(_activeBreak.start).format("HH:mm")}`}><div><FontAwesome className="onbreak" name="clock" /></div></Tippy>
                  : _activeBreak?.id && _activeBreak.status === "INTRANSFER_TO_BREAK" ?
                    <Tippy content={`In transfer to break (${_activeBreak.type}) since ${moment(_activeBreak.createdAt).format("HH:mm")}`}><div><FontAwesome className="instrasfer" name="clock" /></div></Tippy>
                  :
                    <span>{` (${counts[rider.slug] || 0}) `}</span>
                  }
                  <span className="rider-name">{rider.name}</span>
                  {switchIcon(_riderDailyBreak)}
                </div>
                {rider.type !== "CONTRACTOR" &&
                  <ContextMenu.Menu id={_riderDailyBreak?.id}>
                    {_activeBreak?.id ?
                      <ContextMenu.Button onClick={() => _changeBreakStatus({id: _activeBreak?.id, status: "FINISHED"})} icon="cog">End break</ContextMenu.Button>
                    :
                      <>
                        {_riderDailyBreak?.status === "REQUESTED" ?
                          <ContextMenu.Button onClick={() => _changeBreakStatus({id: _riderDailyBreak?.id, status: "INTRANSFER_TO_BREAK"})} icon="utensils">Send to eat</ContextMenu.Button>
                        : !_riderDailyBreak?.status ?
                          <ContextMenu.Button onClick={() => _requestDailyBreak(rider?.id)} icon="utensils">Send to eat</ContextMenu.Button>
                        : null}
                        <ContextMenu.Button onClick={() => _sendRiderToBreak({rider: rider?.id, type: "TECHNICALPROBLEM"})} icon="motorcycle">Problems with the motor</ContextMenu.Button>
                        <ContextMenu.Button onClick={() => _sendRiderToBreak({rider: rider?.id, type: "TECHNICALPROBLEM"})} icon="mobile">Technical problem</ContextMenu.Button>
                        <ContextMenu.Button className="invalid" onClick={() => _sendRiderToBreak({rider: rider?.id, type: "PENALIZED"})} icon="exclamation">Not responding</ContextMenu.Button>
                      </>
                    }
                  </ContextMenu.Menu>
                }
              </div>        
            )
          })}
        </div>        
      </Modalv3>    
    )
  }