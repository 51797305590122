import React, { useState } from 'react'
import { Query } from '@apollo/client/react/components'
import { toast } from 'react-toastify'
import FontAwesome from 'react-fontawesome'

import client from '../../client'
import { Loader, LabeledTextarea, Button } from '../../components'
import { PURCHASE_QUERY, CREATE_PURCHASE_NOTE_MUTATION, DELETE_PURCHASE_NOTE_MUTATION } from '../../graphql'

import './PurchaseNotes.css'

export default function PurchaseNotes({purchase, cancel}){

  const [_loading, toggleLoading] = useState(false)
  const [buttonLoading, toggleButton] = useState(false)
  const [note, setNote] = useState("")

  const addNote = async () => {

    toggleButton(true)

    try {
      await client.mutate({mutation: CREATE_PURCHASE_NOTE_MUTATION, variables: {purchase, note}, refetchQueries: [{query: PURCHASE_QUERY, variables: {id: purchase}}]})
      toggleButton(false)
      setNote("")
      return toast.success("Note added successfully")
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) e.graphQLErrors.map(x => toast.error(x.message))
      console.log("error", e)
      toggleButton(false)
    }
  }

  const deleteNote = async (id) => {
    toggleLoading(true)

    try {
      await client.mutate({mutation: DELETE_PURCHASE_NOTE_MUTATION, variables: {id}, refetchQueries: [{query: PURCHASE_QUERY, variables: {id: purchase}}]})
      toggleLoading(false)
      return toast.success("Note removed successfully")
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) e.graphQLErrors.map(x => toast.error(x.message))
      console.log("error", e)
      toggleLoading(false)
    }
  }

  return(
    <Query query={PURCHASE_QUERY} variables={{id: purchase}}>
      {({ loading, error, data }) => {
        if (loading) return <Loader theme="main"/>
        if (error) return `Error! ${error.message}`

        const { purchase } = data

        return(
          <div id="purchase-notes">
            <div className="purchase-notes">
              <Loader loading={_loading} overlay />
              {purchase.notes && purchase.notes.map((purchaseNote, key) =>
                <div className="purchase-note" key={key}>
                  <div className="info">
                    <div className="owner">{purchaseNote.ownerName}</div>
                    <div className="note">
                      {purchaseNote.note}
                    </div>
                  </div>
                  <div className="actions">
                    <FontAwesome name="trash" onClick={() => deleteNote(purchaseNote.id)}/>
                  </div>
                </div>
              )}
            </div>
            <LabeledTextarea id="purchase-note" placeholder="Write your note here" onChange={(e) => setNote(e.target.value)}>{note}</LabeledTextarea>
            <div className="actions">
              <Button theme="main" loading={buttonLoading} onClick={() => addNote()}>Save</Button>
              <Button onClick={cancel}>Cancel</Button>
            </div>
          </div>
        )
      }}
    </Query>
  )

}
