import React from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { EDIT_CUSTOMER_MUTATION } from '../../../graphql'
import { LabeledCheckbox, EditBox, Form } from '../../../components'

export function CustomerSettings({customer}){

  const [editCustomer] = useMutation(EDIT_CUSTOMER_MUTATION)

  async function _editCustomer(data){
    try {
      await editCustomer({variables: {id: customer?.id, data: data}})
      return toast.success("Successfully changed")
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="settings">
      <Form.Form>
        <Form.Field>
          <LabeledCheckbox rounded placeholder="Abusive" id="abusive" checked={customer.abusive} onChange={(e) => _editCustomer({[e.target.id]: e.target.checked})}/>
        </Form.Field>
        <Form.Field>
          <LabeledCheckbox rounded placeholder="Banned" id="banned" checked={customer.banned} onChange={(e) => _editCustomer({[e.target.id]: e.target.checked})}/>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder="Customer note" save={(value) => _editCustomer({note: value})}>{customer.note || ""}</EditBox.Text>
        </Form.Field>
      </Form.Form>
    </div>
  )

}
