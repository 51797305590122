import React from 'react'
import FontAwesome from 'react-fontawesome'

import { IntlText, Wrapper } from '../../components'

import './style.css'

export function NotFound() {
  return(
    <Wrapper>
      <div id="not-found" className="container">
        <div className="row padding">
          <div id="not-found-wrapper">
            <FontAwesome name="exclamation-triangle"/>
            <p className="error-text"><IntlText group="not-found" id="message">The requested page not found or already moved to another location.</IntlText></p>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
