import React, { useRef, useContext } from 'react'
import { Query } from '@apollo/client/react/components'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { DELETE_ADDRESS_MUTATION, ADDRESSES_QUERY } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { Loader, Button, Modal, Title, IntlText } from '../../../components'
import { CustomerAddress } from '../../../views'

import './style.css'

export function CustomerAddresses({customer}) {

  const modal = useRef(null)
  const { getTranslation } = useContext(IntlContext)
  const [deleteAddress] = useMutation(DELETE_ADDRESS_MUTATION, {refetchQueries: [{query: ADDRESSES_QUERY, variables: {owner: customer.id}}]})

  async function _deleteAddress(id){
    try {
      await deleteAddress({variables: {id}})
      toast.success(getTranslation({id: "delete-success"}))
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) error.graphQLErrors.map(x => toast.error(x.message))
      console.log("Error: ", error)
    }
  }

  return(
    <Query query={ADDRESSES_QUERY} variables={{owner: customer.id}}>
      {({ loading, error, data }) => {
        if (loading) return <Loader theme="main"/>
        if (error) return `Error! ${error.message}`

        const { addresses } = data

        return(
          <div id="addresses">
            {addresses.map((address, key) =>
              <div key={key} className="address">
                <div className="info">
                  <span>{address.name}</span>
                  <span className="building">{address.zone}</span>
                </div>
                <div className="actions">
                  <Button icon="info" onClick={() => modal.current?.show({address})} />
                  <Button theme="reject" onClick={() => _deleteAddress(address.id)} icon="trash" />
                </div>
              </div>
            )}
            <Modal ref={modal} id="customer-address-modal" header={<Title tag="h3"><IntlText group="customer-address-modal" id="title" /></Title>}>
              {({state}) => 
                <CustomerAddress address={state.address} />
              }
            </Modal>        
          </div>
        )
      }}
    </Query>
  )
}
