import React from 'react'
import { useMutation } from '@apollo/client'
import FontAwesome from 'react-fontawesome'
import Tippy from '@tippyjs/react'
import moment from 'moment'

import { EDIT_VENDOR_MUTATION } from '../../../graphql'
import { useConfirm, useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { IntlText, Loader } from '../../../components'

import './style.css'

export function VendorTags({vendor, editable = false}) {

  const tags = [
    {id: "bio", color: "green", icon: <FontAwesome name="recycle" />, label: "Eco friendly package"},
    {id: "new", color: "main", icon: <FontAwesome name="star" />, label: `New on tomato.mx ${moment(vendor.createdAt).format("YYYY-MM-DD")}`},
    {id: "promotion", color: "main", icon: <i className="fas fa-badge-percent"></i>, label: "Promotion"},
    {id: "credit-card", color: "main", icon: <i className="fa fa-credit-card"></i>, label: "Credit card payment option"},
    {id: "slow", color: "warning", icon: <FontAwesome name="clock" />, label: "Slow preparation time"},
    {id: "exclusive", color: "main", icon: <FontAwesome name="badge-check" />, label: "Exclusive"},
  ]
  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const [editVendor, { loading }] = useMutation(EDIT_VENDOR_MUTATION)

  function hasTag(tag){
    if (!vendor?.tags) return null

    return vendor.tags.includes(tag)
  }

  async function removeTag(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      if (!editable || !vendor?.tags?.length > 0) return false
      let data = vendor.tags.filter((_d) => _d !== id)

      if (!data) throw new Error("Nothing to remove")

      await confirm()
      await editVendor({variables: {id: vendor.id, data: {tags: data}}})
      alert.success(getTranslation({id: "change-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  if (loading) return <Loader theme="main" />

  return(
    <div className="vendor-tags">
      {tags.map((tag, key) => hasTag(tag.id) &&
        <div className="vendor-tag" key={key} onClick={() => removeTag(tag.id)}>
          <Tippy content={<IntlText group="vendor-tags" id={tag.id}>{tag.label}</IntlText>}><div className={tag.color}>{tag.icon}</div></Tippy>
        </div>
      )}
      {vendor?.bankCardPaymentsAllowed &&
        <div className="vendor-tag">
          <Tippy content={<IntlText group="vendor-tags" id="card-payment" />}><div className="main"><FontAwesome name="credit-card" /></div></Tippy>
        </div>
      }
    </div>
  )
}
