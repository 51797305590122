import { gql } from '@apollo/client'

import { ANNOUNCEMENT_FRAGMENT } from './'

export const UPLOAD_ANNOUNCEMENT_MEDIA = gql`
  mutation uploadAnnouncementMedia($announcement: ID!, $file: Upload!, $type: ANNOUNCEMENT_UPLOAD_TYPE!){
    uploadAnnouncementMedia(announcement: $announcement, file: $file, type: $type){
      ...ANNOUNCEMENT_FRAGMENT
    }
  }
  ${ANNOUNCEMENT_FRAGMENT}
`
