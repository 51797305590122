import React, { useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'

import { VENDOR_QUERY, CREATE_VENDOR, EDIT_VENDOR_MUTATION } from '../../graphql'
import { useValidatedForm, useStepNavigation } from '../../hooks'
import { validateRfc, validateName, toast } from '../../helpers'
import { IntlContext } from '../../contexts'
import { IntlText, Wrapper, Grids, Steps, Title, Loader } from '../../components'
import { OpeningHours, VendorLocation, NewVendorForm, VendorMedia, VendorInvoicingForm } from '../../views'

export function NewVendor() {

  const items = [
    <IntlText group="vendor-modal" id="steps-details" />,
    <IntlText group="vendor-modal" id="steps-opening-hours" />,
    <IntlText group="vendor-modal" id="steps-location" />,
    <IntlText group="vendor-modal" id="steps-invoicing" />,
    <IntlText group="vendor-modal" id="steps-media" />
  ]

  const validations = {
    name: [{id: "required", validation: (val) => !!val}],
    username: [{id: "required", validation: (val) => !!val}],
    email: [{id: "required", validation: (val) => !!val}],
    phone: [{id: "required", validation: (val) => !!val && val?.phoneNumber}],
    password: [{id: "required", validation: (val) => !!val}],
    type: [{id: "required", validation: (val) => !!val}],
    contract: [{id: "required", validation: (val) => !!val}],
    serviceFee: [{id: "required", validation: (val) => !!val}],
  }

  const navigate = useNavigate()
  const { id } = useParams()
  const steps = useStepNavigation({steps: items})
  const { form, updateField, resetForm, errors } = useValidatedForm({
    serviceFee: 0.15, 
    type: "RESTAURANT",
    contract: "BASIC",
    serviceFeeChargeType: "PERIODICAL",
    serviceFeeChargePeriod: "WEEKLY",
  }, validations)
  const { getTranslation } = useContext(IntlContext)
  const [createVendor, { loading: createLoading }] = useMutation(CREATE_VENDOR)
  const [editVendor, { loading: editLoading }] = useMutation(EDIT_VENDOR_MUTATION)
  const {loading, error, data} = useQuery(VENDOR_QUERY, {variables: {id}, skip: !id})

  if (loading || error) return <Loader theme="main" />

  async function save(){
    try {
      const { data: { createVendor: _vendor } } = await createVendor({variables: {data: {...form, invoicing: {email: form.email}}}})
      resetForm()
      navigate(`/vendors/new/${_vendor.id}`)
      steps.change(4)
    } catch (e) {
      toast.error(e.message)
      console.log("Error: ", e)
    }
  }
  
  async function finish(){
    try {
      await editVendor({variables: {id, data: {tags: ["new", "firstLogin", "canUploadPhotos"], hidden: false, technicalBreak: true, expressFlowEnabled: true, }}})
      toast.success(getTranslation({id: "save-success"}))
      navigate(`/vendors`)
    } catch (error) {
      console.log("Error: ", error)
    }
  }

  return(
    <Wrapper>
      <div id="vendors" className="container">
        <div className="row padding">
          <Grids.Grids>
            <Grids.Grid>
              <Steps.Steps>
                <Steps.Nav {...steps}/>
                <Steps.Content step={1} {...steps}>
                  <div style={{display: "flex", gap: 20}}>
                    <div style={{flex: 1}}>
                      <Title tag="h3" style={{fontSize: "var(--font-l)", fontWeight: 600, textAlign: "center", marginBottom: 20}}>
                        <IntlText group="vendor-modal" id="steps-details" />
                      </Title>
                      <NewVendorForm form={form} errors={errors} updateField={updateField}/>
                    </div>
                  </div>
                  <Steps.Actions><Steps.Next {...steps}/></Steps.Actions>
                </Steps.Content>
                <Steps.Content step={2} {...steps}>
                  <div style={{flex: 1}}>
                    <Title tag="h3" style={{fontSize: "var(--font-l)", fontWeight: 600, textAlign: "center", marginBottom: 20}}>
                      <IntlText group="vendor-modal" id="steps-opening-hours" />
                    </Title>
                    <OpeningHours openingHours={form.openingHours} onChange={(data) => updateField({key: "openingHours", value: data?.openingHours})}/>
                  </div>
                  <Steps.Actions>
                    <Steps.Prev {...steps}/>
                    <Steps.Next {...steps}/>
                  </Steps.Actions>
                </Steps.Content>
                <Steps.Content step={3} {...steps}>
                  <VendorLocation pickupInformation={form.pickupInformation} location={form.location} onChange={(data) => updateField({key: "location", value: data?.location})} />
                  <Steps.Actions>
                    <Steps.Prev {...steps}/>
                    <Steps.Submit onClick={save} loading={createLoading} theme="">Next</Steps.Submit>
                  </Steps.Actions>
                </Steps.Content>
                <Invoicing vendor={data?.vendor} steps={steps} />
                <Steps.Content step={5} {...steps}>
                  <VendorMedia vendor={data?.vendor} />
                  <Steps.Actions>
                    <Steps.Submit onClick={finish} loading={editLoading} />
                  </Steps.Actions>
                </Steps.Content>
              </Steps.Steps>
            </Grids.Grid>
          </Grids.Grids>
        </div>
      </div>
    </Wrapper>
  )
}

function Invoicing({vendor, steps}){
  
  const validations = {
    "name": [{id: "required", validation: (val) => !!val}, {id: "validName", validation: (val) => validateName(val)}],
    "rfc": [{id: "required", validation: (val) => !!val}, {id: "rfc", validation: (val) => validateRfc(val)}],
    "city": [{id: "required", validation: (val) => !!val}],
    "zip": [{id: "required", validation: (val) => !!val}],
    "state": [{id: "required", validation: (val) => !!val}],
    "taxSystem": [{id: "required", validation: (val) => !!val}],
  }
  const { getTranslation } = useContext(IntlContext)
  const [editVendor] = useMutation(EDIT_VENDOR_MUTATION)
  const form = useValidatedForm({...vendor?.invoicing}, validations)
  const { form: {name, rfc, street, exterior, interior, neighborhood, city, zip, municipality, state, taxSystem}, validate } = form

  async function submit(){
    const alert = toast.loading()
    try {
      if (vendor.needsInvoiceOfServicefee){
          const { valid } = validate()
      
          if (!valid) return alert.error(getTranslation({group: "form", id: "error"}))
      
          let data = {
            invoicing:{
              name,
              rfc,
              street,
              exterior,
              interior,
              neighborhood,
              city,
              zip,
              municipality,
              state,
              taxSystem
            }
          }
      
          await editVendor({variables: {id: vendor?.id, data}})
      }
      alert.success(getTranslation({id: "save-success"}))
      steps.next()
      
    } catch (error) {
      alert.error(error.message)
    }
  }

  return(
    <Steps.Content step={4} {...steps}>
      <VendorInvoicingForm form={form} vendor={vendor} />
      <Steps.Actions>
        <Steps.Prev {...steps}/>
        <Steps.Next change={submit} />
      </Steps.Actions>
    </Steps.Content>    
  )
}
