import React from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { PURCHASES_PAGINATED_QUERY } from '../../../graphql'
import { useQueryBuilder } from '../../../hooks'
import { Loader, Pagination, QueryResults } from '../../../components'
import { OrdersList, HistoryFilter } from '../../../views'

export function CustomerOrders({customer}) {

  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const { params, getParams, addParam, removeParam, clearParams, isQuery, buildQuery, runQuery, updateQuery, setPage, page, sort, setSort } = useQueryBuilder({
    data: {
      date: {key: "date", label: `${moment().subtract(90, 'days').startOf('day').format('YYYY-MM-DD')}-${moment().endOf("day").format('YYYY-MM-DD')}`, value: [moment().subtract(90, 'days').startOf('day').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]},
      sort: {key: "payment", label: search.get("sort"), value: search.get("sort") || "CREATEDAT_DESCENDING"},
    },
  })

  const { data, loading, error } = useQuery(PURCHASES_PAGINATED_QUERY, {variables: {customer: customer.id, ...buildQuery()}, skip: !isQuery() || !customer?.id})

  if (error) return `Error! ${error.message}`

  return(
    <div id="customer-orders-history">
      <HistoryFilter loading={loading} params={params} getParams={getParams} addParam={addParam} removeParam={removeParam} clearParams={clearParams} sort={sort} setSort={setSort} runQuery={runQuery} updateQuery={updateQuery} />
      {loading ?
        <Loader theme="main"/>
      :
        <div id="pending-orders">
          <QueryResults pageInfo={data?.purchasesPaginated?.pageInfo} reset={clearParams} />
          <OrdersList data={data?.purchasesPaginated?.nodes || []} visibleFields={{customer: false}} />
          <Pagination page={page} setPage={setPage} pageInfo={data?.purchasesPaginated?.pageInfo}/>
        </div>
      }
    </div>
  )
}
