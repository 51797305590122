import React from 'react'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'
import { useMutation } from '@apollo/client'

import { isFunction } from '../../../helpers'
import { EDIT_INVOICE_MUTATION } from '../../../graphql'
import { Loader } from '../../../components'

export default function InvoiceStatus({invoice}) {

  const [editInvoice, { loading }] = useMutation(EDIT_INVOICE_MUTATION)

  const statusOptions = [
    {status: "SUBMITED", label: "Submited", timestamp: (invoice) => invoice.createdAt, active: ["SUBMITED"], visited: ["INREVIEW", "REJECTED", "COMPLETED", "DISPUTE", "INREVISION"]},
    {status: "INREVIEW", label: "In review", timestamp: (invoice) => invoice.updatedAt, active: ["INREVIEW"], visited: ["REJECTED", "COMPLETED", "DISPUTE", "INREVISION"], hideTime: true},
    {status: "DISPUTE", label: "Dispute", timestamp: (invoice) => invoice.updatedAt, active: ["DISPUTE"], visited: [], hidden: true, hideTime: true},
    {status: "INREVISION", label: "In revision", timestamp: (invoice) => invoice.updatedAt, active: ["INREVISION"], visited: [], hidden: true, hideTime: true},
    {status: "COMPLETED", label: "Completed", timestamp: (invoice) => invoice.updatedAt, active: ["COMPLETED"], visited: [], hideTime: true},
    {status: "REJECTED", label: "Rejected", timestamp: (invoice) => invoice.updatedAt, active: ["REJECTED"], visited: [], hideTime: true},
  ]

  function isActive(active = [], visited = []){
    if (active.includes(invoice.status))
      return "active"

    if (visited.includes(invoice.status))
      return "visited"

    return ""
  }

  return(
    <div id="invoice-status" className="status-bar">
      {loading && <Loader theme="main" overlay />}
      {statusOptions.map((status, key) => {
        if (status.hidden && !status.active.includes(invoice.status)) return null

        return(
          <div key={key} className={`status-bar-item ${isActive(status.active, status.visited)}`}>
            <FontAwesome name="check" />
            <div className="status-name" onClick={() => editInvoice({variables: {id: invoice.id, data: {status: status.status}}})}>
              <span>{isFunction(status.label) ? status.label(invoice) : status.label}</span>
              {(!status.hideTime || (status.hideTime && status.active.includes(invoice.status))) && status.timestamp(invoice) &&
                <span className="time"><FontAwesome name="clock" />{moment(status.timestamp(invoice)).format("YYYY-MM-DD HH:mm")}</span>
              }
            </div>
            {isFunction(status.actions) &&
              <div className="actions">{status.actions(invoice)}</div>
            }
          </div>
        )
      })}
    </div>
  )
}
