import React from 'react'
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import history from './history'
import { Subscriptions } from './subscriptions'

import { PrivateRoute } from './components'
import { PurchaseStatusModal, PurchaseNotesModal, SettingModal } from './views'
import { 
  Admins, NewAdmin, 
  Customers, Customer, DeletedCustomers, CustomerBookEntries, ReferredCustomers,
  Riders, Rider, NewRider, RiderPayout, RiderBreaks, Workshifts, RiderWorkshift, RiderDayClosing, RidersPayouts,
  Product, 
  Dispatcher, 
  Orders, Order, 
  Invoices, Invoice, 
  Modifications, 
  Changelogs, Changelog, NewChangelog, 
  Pages, Page, NewPage, 
  Announcements, Announcement, NewAnnouncement, 
  Faqs, Faq, NewFaq, 
  Import, 
  NotFound, 
  Settings, 
  Vendors, Vendor, NewVendor, VendorsInvoices, VendorsBookEntries,
  Audit, 
  PurchaseQuotes,
  Dashboard, 
} from './containers'

export function Router() {

  return (
    <BrowserRouter history={history}>
      <Subscriptions />
      <div id="modal-portal" />
      <Routes>
        <Route path="/" element={
          <PrivateRoute auth={(user) => user?.superAdmin} fallback={<Dispatcher />}>
            <Dashboard />
          </PrivateRoute>
        } />
        <Route path={"/tasks"} element={
          <PrivateRoute>
            <Dispatcher />
          </PrivateRoute>
        } />
        <Route path={"/orders"} element={
          <PrivateRoute>
            <Orders />
          </PrivateRoute>
        } />
        <Route path="/purchase-quotes" element={
          <PrivateRoute>
            <PurchaseQuotes />
          </PrivateRoute>
        } />
        <Route path={"/orders/:id"} element={
          <PrivateRoute>
            <Order />
          </PrivateRoute>
        } />
        <Route path={"/admins/new"} element={
          <PrivateRoute>
            <NewAdmin />
          </PrivateRoute>
        } />
        <Route path={"/admins"} element={
          <PrivateRoute>
            <Admins />
          </PrivateRoute>
        } />
        <Route path={"/accounting/modifications"} element={
          <PrivateRoute>
            <Modifications />
          </PrivateRoute>
        } />
        <Route path={"/faqs"} element={
          <PrivateRoute>
            <Faqs />
          </PrivateRoute>
        } />
        <Route path={"/faqs/new"} element={
          <PrivateRoute>
            <NewFaq />
          </PrivateRoute>
        } />
        <Route path={"/faqs/new/:language"} element={
          <PrivateRoute>
            <NewFaq />
          </PrivateRoute>
        } />
        <Route path={"/faqs/:id"} element={
          <PrivateRoute>
            <Faq />
          </PrivateRoute>
        } />
        <Route path={"/faqs/:id/:language"} element={
          <PrivateRoute>
            <Faq />
          </PrivateRoute>
        } />
        <Route path={"/pages"} element={<AuthenticatedRoute />}>
          <Route path="" element={<Pages />} />
          <Route path="new" element={<NewPage />} />
          <Route path="new/:language" element={<NewPage />} />
          <Route path=":id/*" element={<Page />} />
          <Route path=":id/:language" element={<Page />} />
        </Route>
        <Route path={"/changelogs"} element={<AuthenticatedRoute />}>
          <Route path="" element={<Changelogs />} />
          <Route path="new" element={<NewChangelog />} />
          <Route path="new/:language" element={<NewChangelog />} />
          <Route path=":id/*" element={<Changelog />} />
          <Route path=":id/:language" element={<Changelog />} />
        </Route>
        <Route path={"/announcements"} element={<AuthenticatedRoute />}>
          <Route path="" element={<Announcements />} />
          <Route path="new" element={<NewAnnouncement />} />
          <Route path="new/:language" element={<NewAnnouncement />} />
          <Route path=":id/*" element={<Announcement />} />
          <Route path=":id/:language" element={<Announcement />} />
        </Route>
        <Route path={"/vendors"} element={<AuthenticatedRoute />}>
          <Route path="" element={<Vendors />} />
          <Route path="new" element={<NewVendor />} />
          <Route path="new/:id" element={<NewVendor />} />
          <Route path=":id/products/:id/*" element={<Product />} />
          <Route path=":id/*" element={<Vendor />} />
          <Route path="invoices" element={<VendorsInvoices />} />
          <Route path="book-entries" element={<VendorsBookEntries />} />
        </Route>
        <Route path={"/riders"} element={<AuthenticatedRoute />}>
          <Route path="" element={<Riders />} />
          <Route path={"new"} element={<NewRider />} />        
          <Route path=":id/*" element={<Rider />} />
          <Route path={"breaks"} element={<RiderBreaks />} />
          <Route path={"payouts"} element={<RidersPayouts />} />
          <Route path={"payouts/:payoutID"} element={<RiderPayout />} />
          <Route path={"workshifts"} element={<Workshifts />} />
          <Route path={"workshifts/:id"} element={<RiderWorkshift />} />
          <Route path={"workshifts/:id/active"} element={<RiderDayClosing />} />
        </Route>
        <Route path={"/customers"} element={<AuthenticatedRoute />}>
          <Route path="" element={<Customers />} />
          <Route path=":id/*" element={<Customer />} />
          <Route path=":id/:view" element={<Customer />} />
          <Route path="deleted" element={<DeletedCustomers />} />
          <Route path="referred" element={<ReferredCustomers />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="invoices/:id" element={<Invoice />} />
          <Route path="book-entries" element={<CustomerBookEntries />} />
        </Route>
        <Route path={"/configuration/*"} element={
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        } />
        <Route path={"/configuration/import"} element={
          <PrivateRoute>
            <Import />
          </PrivateRoute>
        } />
        <Route path={"/audit"} element={
          <PrivateRoute>
            <Audit />
          </PrivateRoute>
        } />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <PurchaseStatusModal />
      <PurchaseNotesModal />
      <SettingModal />
      <ToastContainer />
    </BrowserRouter>
  )

}

function AuthenticatedRoute(){
  return(
    <PrivateRoute>
      <Outlet />
    </PrivateRoute>
  )
}