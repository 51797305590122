import React from 'react'
import FontAwesome from 'react-fontawesome'
import Tippy from '@tippyjs/react'

import { stringToColor } from '../../../../helpers'

export function OrderTagsField({purchase}) {

  return(
    <div className="order-tags-field">
      <div className="tags">
        {purchase.voided &&
          <Tippy content="Voided"><div><FontAwesome name="gift" /></div></Tippy>
        }
        {purchase.refunds?.length > 0 &&
          <Tippy content="Has refund"><div><FontAwesome name="undo" /></div></Tippy>
        }
        {purchase.banknote &&
          <Tippy content={`Change for ${purchase.banknote}`}><div><FontAwesome name="money-bill" /></div></Tippy>
        }
        {purchase.payment && (purchase.payment === "CARD_CONEKTA" || purchase.payment === "CARD_MERCADOPAGO" || purchase.payment === "CARD_STRIPE") &&
          <Tippy content={`Paid with online payment via ${purchase.payment}. Current status ${purchase.paymentStatus}`}><div><FontAwesome name="credit-card" /></div></Tippy>
        }
        {purchase.customerNote &&
          <Tippy content={`${purchase.customerNote}`}><div><FontAwesome name="info-circle" /></div></Tippy>
        }
        {purchase.isMobileApp &&
          <Tippy content={`Mobile app order`}><div><FontAwesome name="mobile" style={{color: "#a3a3a3"}}/></div></Tippy>
        }
        {purchase.notes && purchase.notes.length > 0 &&
          <Tippy content={`${purchase.notes.length} ${purchase.notes.length === 1 ? "note" : "notes"}`}><div><FontAwesome name="sticky-note" /></div></Tippy>
        }
        {purchase.group && <FontAwesome name="link" style={{color:  stringToColor(purchase.group)}}/>}
      </div>
    </div>
  )
}
