import React, { useState, useRef, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'

import { RIDERS_QUERY, ASSIGN_RIDER_MUTATION, REMOVE_RIDER_MUTATION, RIDER_STATUS_CHANGED_SUBSCRIPTION } from '../../../graphql'
import { useConfirm, useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { Loader, LabeledSelect, Tooltip, StatusDot } from '../../../components'

import './style.css'

export function AssignRider({purchase}) {

  function onCompleted(){
    if (isMounted.current) toggleEdit(false)
  }
  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const {loading, error, data, subscribeToMore} = useQuery(RIDERS_QUERY, {variables: {active: true}})
  const [assignRider] = useMutation(ASSIGN_RIDER_MUTATION, {onCompleted})
  const [removeRider] = useMutation(REMOVE_RIDER_MUTATION)
  const [edit, toggleEdit] = useState(false)
  const isMounted = useRef(null)

  useEffect(() => {
    subscribeToMore({
      document: RIDER_STATUS_CHANGED_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const _riders = [...prev?.riders]
        const index = _riders.findIndex((purchase) => purchase.id === subscriptionData.data.riderStatusChange.id)
        if (index >= 0){
          _riders[index] = subscriptionData.data.riderStatusChange
          return Object.assign({}, prev, {
            riders: _riders
          })
        } else {
          return Object.assign({}, prev, {
            riders: [subscriptionData.data.riderStatusChange, ..._riders]
          })
        }
      }
    })
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  const { riders } = data

  async function _removeRider(){
    try {
      await removeRider({variables: {id: purchase.id}})
      toggleEdit(false)
    } catch (e) {
      console.log("Error ", e);
    }
  }

  function renderItem(_item){
    return(
      <div className='rider-option'><StatusDot active={!!(_item.active && _item.available)} />{_item.name}</div>
    )
  }

  async function onChangeWithAction(rider){
    const alert = toast.loading()

    try {
      if (!(rider.active && rider.available)){
        alert.update(getTranslation({group: "confirm", id: "confirm-to-continue"}))
        await confirm({title: getTranslation({group: "assign-rider", id: "assign-to-unavailable"}), desc: getTranslation({group: "assign-rider", id: "assign-to-unavailable-info"})})      
        alert.update(getTranslation({id: "loading"}))
        await assignRider({variables: {id: purchase.id, rider: rider.id}})
      } else {
        await assignRider({variables: {id: purchase.id, rider: rider.id}})
      }
      alert.success(getTranslation({group: "assign-rider", id: "assign-success", variables: {name: rider.name}}))
    } catch (error) {
      console.log("Error", error)
    }
  }

  return(
    <div className="assign-rider-wrapper">
      {!edit && purchase.rider ?
        <div className="assigned-rider">
            {purchase.arrivedAtVendor
              ? <>
                  <Tooltip text={`Rider confirmed order at ${moment(purchase.timestamps.riderConfirmed).format("HH:mm")}`}><FontAwesome name="check" /></Tooltip>
                  <Tooltip text={`Rider arrived to vendor at ${moment(purchase.timestamps.arrivedAtVendor).format("HH:mm")}`}><FontAwesome name="map-marker" /></Tooltip>
                </>
              : purchase.riderConfirmed
              ? <Tooltip text={`Rider confirmed order at ${moment(purchase.timestamps.riderConfirmed).format("HH:mm")}`}><FontAwesome name="check" /></Tooltip>
              : <Tooltip text={`Rider received the notification at ${moment(purchase.timestamps.assignedToRider).format("HH:mm")}`}><FontAwesome name="times" /></Tooltip>
            }
            <span className="link">{purchase.rider.name}</span>
          <FontAwesome name="edit" onClick={() => toggleEdit(true)}/>
        </div>
      :
        <div className="assign-rider">
          <LabeledSelect id={purchase.id} renderItem={renderItem} field="name" options={riders} placeholder="Select rider" onChangeWithAction={onChangeWithAction}/>
          {edit &&
            <div className="actions">
              <FontAwesome className="remove" name="trash" onClick={_removeRider}/>
              <FontAwesome className="cancel" name="times" onClick={() => toggleEdit(false)}/>
            </div>
          }
        </div>
      }
    </div>
  )
}
