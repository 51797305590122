import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'

import { ModalContext } from '../../../../contexts'
import { toast } from '../../../../helpers'
import { ContextMenu, Authentication } from '../../../../components'
import { REMOVE_GROUP_MUTATION, EDIT_PURCHASE_MUTATION, CREATE_REFUND_MUTATION, PURCHASES_PAGINATED_QUERY } from '../../../../graphql'

export function ActionsField({purchase, children}) {

  const { openModal, modals } = useContext(ModalContext)
  const [editPurchase] = useMutation(EDIT_PURCHASE_MUTATION)
  const [removeGroup] = useMutation(REMOVE_GROUP_MUTATION)

  async function _editPurchase() {
    try {
      await editPurchase({variables: {id: purchase.id, data: {problem: !purchase.problem}}})
    } catch (e) {
      console.log("Error: ", e)
    }
  }

  async function _removeGroup() {
    try {
      await removeGroup({variables: {group: purchase.group}})
    } catch (e) {
      console.log("Error: ", e)
    }
  }

  return(
    <Authentication>
      {(userCanSee) =>
        <div className="actions-field">
          <ContextMenu.Menu id={purchase?.id}>
            {children}
            <ContextMenu.Link to={`/orders/${purchase.id}`} icon="external-link-alt">View purchase</ContextMenu.Link>
            <ContextMenu.Button onClick={() => modals.purchaseProducts.show(purchase)} icon="shopping-cart">Check products</ContextMenu.Button>
            <ContextMenu.Button onClick={() => openModal("purchaseStatus", {purchase})} icon="bells">Check status</ContextMenu.Button>
            <ContextMenu.Button onClick={() => modals.riderModifications.show(purchase.id)} icon="sticky-note">Add modification</ContextMenu.Button>
            {userCanSee &&
              <RefundButton purchase={purchase} />
            }
            {purchase.group &&
              <ContextMenu.Button onClick={_removeGroup} icon="unlink">Remove group</ContextMenu.Button>
            }
            <ContextMenu.Button onClick={_editPurchase} icon="exclamation-triangle">{purchase.problem ? "Remove problem mark" : "Mark problem"}</ContextMenu.Button>
            {["DELIVERYFAILED", "CANCELLED", "REJECTED", "UNPROCESSED", "COMPLETED"].includes(purchase.status) ?
              <ContextMenu.Button className="warning" onClick={() => modals.cancelPurchase.show(purchase)} icon="undo-alt">Reset purchase</ContextMenu.Button>
            :
              <ContextMenu.Button className="invalid" onClick={() => modals.cancelPurchase.show(purchase)} icon="window-close">Cancel purchase</ContextMenu.Button>
            }
          </ContextMenu.Menu>
        </div>
      }
    </Authentication>
  )

}

function RefundButton({purchase}){

  const [createRefund] = useMutation(CREATE_REFUND_MUTATION)

  async function _refund(){
    const alert = toast.loading()

    try {
      let {data: { createRefund: { value }}} = await createRefund({variables: { data: {
        purchase: purchase.id, 
        value: purchase.price.chargeOnCard, 
        discountableFromVendor: purchase.price.vendorPrice, 
        reason: "REQUESTED_BY_CLIENT",
        note: "Order cancelled by the client"
      }}, refetchQueries: [PURCHASES_PAGINATED_QUERY]})
      alert.success(`${value} $ successfully refunded!`)
    } catch (e) {
      alert.apolloError(e)
    }
  }  

  if (purchase.payment !== "CARD_STRIPE") return null

  return(
    <ContextMenu.Button onClick={_refund} icon="undo">Refund</ContextMenu.Button>
  )
}
