import React, { useContext, useState } from 'react'
import { Query } from '@apollo/client/react/components'
import FontAwesome from 'react-fontawesome'

import { IntlContext, ModalContext } from '../../../contexts'
import { useModal } from '../../../hooks'
import { PURCHASE_QUERY } from '../../../graphql'
import { Title, IntlText, Modalv3, Table, Loader, CheckboxField, Button, Form } from '../../../components'
import { PurchaseAddress, PurchaseCustomer, CustomerBalance, CustomerBalanceHistory, PurchaseCancel, PurchaseModifications, PurchaseCart } from '../../../views'
import { OrdersListItem } from './item'

import './style.css'

const defaultFields = {
  vendor: true,
  customer: true,
  zone: true,
  basketValue: false,
  deliveryFee: false,
  tax: false,
  total: true,
  createdAt: true,
  cookingTime: true,
  deliveryTime: true,
  rider: true,
}

export function OrdersList({data, selected, selectItem, unselectItem, visibleFields = {}, dateFormat}) {

  const { modals } = useContext(ModalContext)
  const [_visibleFields, setVisibleFields] = useState({...defaultFields, ...visibleFields})

  return(
    <div id="orders-list" >
      <Table.Table>
        <Table.Head>
          <Table.Tr>
            <Table.Th>#</Table.Th>
            {_visibleFields.vendor && 
              <Table.Th className="text-left">Vendor</Table.Th>
            }
            {_visibleFields.customer && 
              <Table.Th className="text-left">Customer</Table.Th>
            }
            {_visibleFields.zone && 
              <Table.Th>Zone</Table.Th>
            }
            {_visibleFields.basketValue && 
              <Table.Th>Basket Value</Table.Th>
            }
            {_visibleFields.deliveryFee && 
              <Table.Th>Delivery Fee</Table.Th>
            }
            {_visibleFields.tax && 
              <Table.Th>Tax</Table.Th>
            }
            {_visibleFields.total && 
              <Table.Th>Total</Table.Th>
            }
            {_visibleFields.createdAt && 
              <Table.Th>Created at</Table.Th>
            }
            {_visibleFields.cookingTime && 
              <Table.Th>CT</Table.Th>
            }
            {_visibleFields.deliveryTime && 
              <Table.Th>DT</Table.Th>
            }
            {_visibleFields.rider && 
              <Table.Th>Rider</Table.Th>
            }
            <Table.Th></Table.Th>
            <Table.Th><VisibleFieldsFilter visibleFields={_visibleFields} setVisibleFields={setVisibleFields} /></Table.Th>
            <Table.Th className="purchase-status"></Table.Th>
          </Table.Tr>
        </Table.Head>
        <Table.Body>
          {data && data.length > 0 ? data.map((purchase, key) =>
            <OrdersListItem key={purchase.id} id={key} purchase={purchase} dateFormat={dateFormat} visibleFields={_visibleFields} selected={selected && selected(purchase.id)} selectItem={selectItem} unselectItem={unselectItem} />
          )
          :
            <Table.Tr>
              <Table.Td colSpan={13}>Not found</Table.Td>
            </Table.Tr>
          }
        </Table.Body>
      </Table.Table>
      <Modalv3 modal={modals.purchaseAddress} header={<Title tag="h3"><IntlText group="purchase-address-modal" id="title" /></Title>}>
        <PurchaseAddress purchase={modals.purchaseAddress.state} />
      </Modalv3>        
      <Modalv3 modal={modals.purchaseAddress} header={<Title tag="h3"><IntlText group="purchase-address-modal" id="title" /></Title>}>
        <PurchaseAddress purchase={modals.purchaseAddress.state} />
      </Modalv3>        
      <Modalv3 modal={modals.purchaseCustomer} header={<Title tag="h3"><IntlText group="purchase-customer-modal" id="title" /></Title>}>
        <PurchaseCustomer purchase={modals.purchaseCustomer.state} />
      </Modalv3>        
      <Modalv3 modal={modals.customerBalance} header={<Title tag="h3"><IntlText group="customer-balance-modal" id="title" /></Title>}>
        <div>
          <CustomerBalance customer={modals.customerBalance.state?.customer?.originalId}/>
          <CustomerBalanceHistory customer={modals.customerBalance.state?.customer?.originalId}/>
        </div>
      </Modalv3>        
      <Modalv3 id="cancel-purchase" contentStyle={{overflow: "hidden"}} modal={modals.cancelPurchase} header={<Title tag="h3"><IntlText group="cancel-purchase" id="modal-title" /></Title>}>
        <PurchaseCancel purchase={modals.cancelPurchase.state} onSuccess={() => modals.cancelPurchase.hide()} />
      </Modalv3>          
      <Modalv3 id="purchase-modifications" contentStyle={{overflow: "hidden"}} modal={modals.riderModifications} header={<Title tag="h3"><IntlText group="purchase-modifications" id="modal-title" /></Title>}>
        <PurchaseModifications purchase={modals?.riderModifications?.state} cancel={() => modals?.riderModifications.hide()}/>
      </Modalv3>          
      <Modalv3 id="purchase-products" contentStyle={{overflow: "auto"}} modal={modals.purchaseProducts} header={<Title tag="h3"><IntlText group="purchase-modifications" id="modal-title" /></Title>}>
        <Query query={PURCHASE_QUERY} variables={{id: modals?.purchaseProducts?.state?.id}} skip={!modals?.purchaseProducts?.state?.id}>
          {({ loading, error, data }) => {
            if (loading) return <Loader theme="main"/>
            if (error) return `Error! ${error.message}`
            return <PurchaseCart purchase={data?.purchase} />
          }}
        </Query>
      </Modalv3>          
    </div>
  )

}

function VisibleFieldsFilter({visibleFields, setVisibleFields}){

  const modal = useModal()
  const { getTranslation } = useContext(IntlContext)

  return(
    <div>
      <Button onClick={() => modal.show()} ><FontAwesome name="cog" /></Button>
      <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="visible-fields-filter" id="title" /></Title>}>
        <Form.Form>
          {Object.entries(visibleFields).map(([key, value]) => 
            <Form.Field key={key}>
              <CheckboxField id={`${key}-field`} checked={value} onChange={() => setVisibleFields({...visibleFields, [key]: !value})} label={getTranslation({group: "visible-fields-filter", id: key})}/>
            </Form.Field>
          )}
        </Form.Form>
      </Modalv3>  
    </div>
  )
}
