import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import { PURCHASE_QUERY, MY_PURCHASE_UPDATED_SUBSCRIPTION } from '../../graphql'
import { useTabs } from '../../hooks'
import { formatPrice } from '../../helpers'
import { tomato } from '../../assets'
import { Wrapper, IntlValue, Grids, Tabs } from '../../components'
import { PurchaseStatus, PurchaseCustomer, PurchaseCart, PurchaseAddress, OrderFeedback, OrderRefunds, PurchaseAudit, ReferralUser } from '../../views'

export function Order() {

  const { id } = useParams()
  const { data, loading, error, subscribeToMore } = useQuery(PURCHASE_QUERY, {variables: {id}, fetchPolicy: "network-only"})
  const tabs = useTabs({defaultTab: "order-products"})

  useEffect(() => {
    const myPurchasesUpdated = subscribeToMore({
      document: MY_PURCHASE_UPDATED_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        if (subscriptionData.data.myPurchasesUpdated.id === prev.purchase.id){
          return Object.assign({}, prev, {
            purchase: subscriptionData.data.myPurchasesUpdated
          })
        }

        return prev

      }
    })

    return () => {
      myPurchasesUpdated()
    }    
  // eslint-disable-next-line    
  }, [])

  if (error) return `Error: ${error}`

  return(
    <Wrapper loading={loading}>
      <div id="order" className="container">
        <div className="row padding">
          <Grids.Grids>
            <Grids.Grid>
              <PurchaseStatus purchase={data?.purchase} />
            </Grids.Grid>
            <Grids.Grid size="2">
              <Tabs.Tabs>
                <Tabs.Menu>
                  <Tabs.Button id="order-products" tabs={tabs}>Products</Tabs.Button>
                  <Tabs.Button id="order-customer" tabs={tabs}>Customer</Tabs.Button>
                  <Tabs.Button id="order-address" tabs={tabs}>Address</Tabs.Button>
                  <Tabs.Button id="order-feedback" tabs={tabs}>Feedback</Tabs.Button>
                  <Tabs.Button id="order-refunds" tabs={tabs}>Refunds</Tabs.Button>
                  <Tabs.Button id="order-referral" tabs={tabs}>Referral</Tabs.Button>
                  <Tabs.Button id="order-book" tabs={tabs}>Book</Tabs.Button>
                  <Tabs.Button id="order-logs" tabs={tabs}>Logs</Tabs.Button>
                </Tabs.Menu>
                <Tabs.Content>
                  <Tabs.Tab id="order-products" tabs={tabs}>
                    <PurchaseCart purchase={data?.purchase} />
                  </Tabs.Tab>
                  <Tabs.Tab id="order-customer" tabs={tabs}>
                    <PurchaseCustomer purchase={data?.purchase} />
                  </Tabs.Tab>
                  <Tabs.Tab id="order-address" tabs={tabs}>
                    <PurchaseAddress purchase={data?.purchase} />
                  </Tabs.Tab>
                  <Tabs.Tab id="order-feedback" tabs={tabs}>
                    <OrderFeedback purchase={data?.purchase} />
                  </Tabs.Tab>
                  <Tabs.Tab id="order-refunds" tabs={tabs}>
                    <OrderRefunds purchase={data?.purchase} />
                  </Tabs.Tab>
                  <Tabs.Tab id="order-referral" tabs={tabs}>
                    <ReferralUser id={data?.purchase?.customer?.referralSourceId} />
                  </Tabs.Tab>
                  <Tabs.Tab id="order-book" tabs={tabs}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Type</th>
                          <th>Note</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.purchase?.customerTransactionEntries?.map((historyItem, key) =>
                          <tr className="item" key={key}>
                            <td className="date">{moment(historyItem?.createdAt).format("YYYY MMMM DD. HH:mm:ss")}</td>
                            <td className="description-field">
                              <div>
                                <span><IntlValue>{historyItem?.description}</IntlValue></span>
                                <span className="message">{historyItem.auditMessage}</span>
                              </div>
                            </td>
                            <td className="note">{historyItem?.note}</td>
                            <td className={`price-field ${historyItem.value > 0 ? "plus" : "minus"}`}>
                              {historyItem?.type === "TOMATO_POINTS" ?
                                <div className="tomato-point"><span>{historyItem?.value}</span><img alt="tomato points" src={tomato} className="icon" /></div>
                              :
                                formatPrice(historyItem?.value)
                              }
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Tabs.Tab>
                  <Tabs.Tab id="order-logs" tabs={tabs}>
                    <PurchaseAudit purchase={data?.purchase} />
                  </Tabs.Tab>
                </Tabs.Content>
              </Tabs.Tabs>
            </Grids.Grid>
          </Grids.Grids>
        </div>
      </div>
    </Wrapper>
  )
}
