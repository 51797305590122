import { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

export function useTabs({group = "tab", defaultTab, skipHistory = false, resetOnLocationChange}) {
  const navigate = useNavigate()
  const location = useLocation()
  const search = useMemo(() => new URLSearchParams(location?.search), [location?.search])
  const [selectedTab, changeTab] = useState(search.get(group) || defaultTab)

  useEffect(() => {
    if (resetOnLocationChange && location?.state?.reset) changeTab(defaultTab)
  }, [location, defaultTab, changeTab, resetOnLocationChange])

  function _changeTab(id){
    if (!skipHistory){
      search.set(group, id)
      navigate({search: search.toString()})
    }
    changeTab(id)
  }

  return {
    changeTab: _changeTab,
    selectedTab
  }
}
