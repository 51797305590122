import React, { useState } from 'react'
import moment from 'moment'
import { useMutation } from '@apollo/client'

import { formatPrice, toast } from '../../../helpers'
import { DELETE_RIDER_PAYOUT } from '../../../graphql'
import { Table, ContextMenu, IntlText, Badge } from '../../../components'

const defaultFields = {
  rider: true,
  username: false,
  createdAt: true,
  status: true,
}

export function RiderPayouts({riderPayouts, refetch, visibleFields = {}}) {

  const [deleteRiderPayout] = useMutation(DELETE_RIDER_PAYOUT)
  const [_visibleFields, setVisibleFields] = useState({...defaultFields, ...visibleFields})

  async function _delete(id){
    const { success, error } = toast.loading()

    try {
      await deleteRiderPayout({variables: {id}})
      if (refetch) refetch()
      
      success("success")
    } catch (e) {
      error(e?.message)
    }

  }

  return(
    <div className="rider-payouts">
      {riderPayouts?.nodes?.length > 0 &&
        <Table.Table  style={{marginTop: 20}}>
          <Table.Head>
            <Table.Tr>
              {_visibleFields.rider && 
                <Table.Th className="text-left">Name</Table.Th>
              }
              {_visibleFields.username && 
                <Table.Th className="text-left">Username</Table.Th>
              }
              <Table.Th>Gross</Table.Th>
              <Table.Th>Reference</Table.Th>
              {_visibleFields.createdAt && 
                <Table.Th>Created at</Table.Th>
              }
              {_visibleFields.status && 
                <Table.Th>Status</Table.Th>
              }
              <Table.Th><Table.VisibleFieldsFilter visibleFields={_visibleFields} setVisibleFields={setVisibleFields} /></Table.Th>
            </Table.Tr>
          </Table.Head>
          <Table.Body>
            {riderPayouts?.nodes?.map((payout, key) =>
              <Table.Tr className="item" key={key}>
                {_visibleFields.rider && 
                  <Table.Td className="text-left">{payout?.rider?.fullName}</Table.Td>
                }
                {_visibleFields.username && 
                  <Table.Td className="text-left">{payout?.rider?.username}</Table.Td>
                }                
                <Table.Td>{formatPrice(payout?.grossInvoiceAmount)}</Table.Td>
                <Table.Td>{payout?.reference}</Table.Td>
                {_visibleFields.createdAt && 
                  <Table.Td className="date">{moment(payout?.createdAt).format("YYYY MMMM DD")}</Table.Td>
                }
                {_visibleFields.status && 
                  <Table.Td>
                    <Badge theme={["WAITING_FOR_INVOICE", "INVOICE_IN_PROCESS", "TRANSFER_IN_PROCESS"].includes(payout.status) ? "warning" : payout.status === "PAID" ? "valid" : null}><IntlText group="rider-payouts" id={payout.status} /></Badge>
                  </Table.Td>
                }
                <Table.Td>
                  <ContextMenu.Menu id={payout?.id}>
                    <ContextMenu.Link to={`${payout.id}`} icon="external-link-alt">View</ContextMenu.Link>
                    {payout.invoiceId &&
                      <ContextMenu.Link to={`/accounting/invoices/${payout.invoiceId}`} icon="external-link-alt">View invoice</ContextMenu.Link>
                    }
                    <ContextMenu.Button className="invalid" icon="trash" onClick={() => _delete(payout.id)}>Delete</ContextMenu.Button>
                  </ContextMenu.Menu>
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Body>
        </Table.Table>  
      }  
    </div>
  )
}