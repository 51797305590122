import React, { useContext, useState } from 'react'
import moment from 'moment'
import FontAwesome from 'react-fontawesome'


import { useModal } from '../../../hooks'
import { IntlContext } from '../../../contexts'
import { Button, IntlText, Badge, ContextMenu, Modalv3, Title, DatePicker, LabeledInput, Thumbnail } from '../../../components'

import './style.css'

const defaultStatusOptions = {
  ACCEPTED: {icon: "check", theme: 'valid', value: "ACCEPTED"},
  REJECTED: {icon: "times", theme: 'invalid', value: "REJECTED"},
  EXPIRED: {icon: "times", theme: 'invalid', value: "EXPIRED"},
  INREVIEW: {icon: "clock", theme: 'warning', value: "INREVIEW"},
  DEFAULT: {icon: "clock", theme: 'ui', value: "WAITING_FOR_UPLOAD"},
}

export function VerifiedDocument({name, url, type = "pdf", status, expiry, accept, reject, edit, message, editMessage, upload, children, statusOptions = defaultStatusOptions, images = []}){

  const modal = useModal()
  const rejectModal = useModal()
  const messageModal = useModal()
  const uploadModal = useModal()

  function _reject() {
    if(editMessage){
      rejectModal?.show() 
    } else{
      reject()
    }
  }


  return(
    <div className='verified-upload'>
      <div className='icon'>
        <FontAwesome name={statusOptions[status]?.icon || statusOptions["DEFAULT"]?.icon} style={{color: `var(--${statusOptions[status]?.theme || statusOptions["DEFAULT"]?.theme}-color)`}}/>
      </div>
      {type === "image" && url &&
        <Thumbnail url={url} title={name} images={images} />
      }
      <div className='name'>{name}</div>
      {edit && url &&
        <div className='expiry'>
          <span className='placeholder'><IntlText group="verified-document" id="expiry" /></span>
          <span style={{display: "flex", alignItems: "center"}}>{expiry ? moment(expiry).format("YYYY-MM-DD") : <div><IntlText id="not-set" /></div>}</span>
        </div>
      }
      <Badge  style={{marginLeft: "auto"}} theme={statusOptions[status]?.theme || statusOptions["DEFAULT"]?.theme}>
        {statusOptions[status]?.value || statusOptions["DEFAULT"]?.value}
      </Badge>           
      <div className='actions'>
        <ContextMenu.Menu>
          {url && accept && status !== "ACCEPTED" && <ContextMenu.Button className='valid' onClick={accept} icon="check"><IntlText id="accept" /></ContextMenu.Button>}
          {type === "pdf" && url &&
            <ContextMenu.Button icon="link"><a href={url} target="blank"><IntlText group="verified-document" id="view-document" /></a></ContextMenu.Button>
          }
          {url && edit && <ContextMenu.Button onClick={() => modal?.show()} icon="calendar"><IntlText group="verified-document" id="edit-expiry" /></ContextMenu.Button>}
          {url && status === "REJECTED" && editMessage && <ContextMenu.Button onClick={() => messageModal?.show()} icon="comment"><IntlText group="verified-document" id="edit-message" /></ContextMenu.Button>}
          {upload && <ContextMenu.Button onClick={() => uploadModal?.show()} icon="upload"><IntlText group="verified-document" id="upload-document" /></ContextMenu.Button>}
          {url && reject && status !== "REJECTED" && <ContextMenu.Button className='invalid' onClick={_reject} icon="times"><IntlText id="reject" /></ContextMenu.Button>}
        </ContextMenu.Menu>
      </div>
      {edit && <ExpiryModal modal={modal} expiry={expiry} edit={edit} />}
      {upload && <UploadModal modal={uploadModal} upload={upload} />}
      {editMessage && 
        <>
          <MessageModal modal={messageModal} message={message} edit={editMessage} />
          <RejectModal modal={rejectModal} message={message} reject={reject} />
        </>
      }   
    </div>    
  )
}

function RejectModal({modal, message, reject}){

  const { getTranslation } = useContext(IntlContext)
  const [_message, setMessage] = useState(message)

  function save(){
    try {
      reject(_message)
      modal.hide()
    } catch (error) {
      console.log("Error", error);
    }
  }        

  function hideModal(){
    setMessage(message)
    modal.hide()
  }        

  return(
    <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="verified-document" id="edit-message" /></Title>} onHide={() => modal.hide()}>
      <LabeledInput placeholder={getTranslation({group: "verified-document", id: "message"})} value={_message} onChange={(e) => setMessage(e.target.value)}/>
      <div className='actions' style={{marginTop: 15}}>
        <Button onClick={hideModal}><IntlText id="cancel" /></Button>
        <Button theme="main" onClick={save}><IntlText id="save" /></Button>
      </div>
    </Modalv3>             
  )    
}


function ExpiryModal({modal, expiry, edit}){

  const [_expiry, setExpiry] = useState(expiry)

  function save(){
    edit(_expiry)
    modal.hide()
  }    

  function hideModal(){
    setExpiry(expiry)
    modal.hide()
  }    

  return(
    <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="verified-document" id="edit-expiry" /></Title>} onHide={hideModal}>
      <DatePicker.Date date={_expiry ? new Date(_expiry) : new Date()} onChange={(_date) => setExpiry(moment(_date).valueOf())} />
      <div className='actions' style={{marginTop: 15}}>
        <Button onClick={hideModal}><IntlText id="cancel" /></Button>
        <Button theme="main" onClick={save}><IntlText id="save" /></Button>
      </div>
    </Modalv3>      
  )
}

function MessageModal({modal, message, edit}){

  const { getTranslation } = useContext(IntlContext)
  const [_message, setMessage] = useState(message)

  function save(){
    edit(_message)
    modal.hide()
  }        

  function hideModal(){
    setMessage(message)
    modal.hide()
  }        

  return(
    <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="verified-document" id="edit-message" /></Title>} onHide={() => modal.hide()}>
      <LabeledInput placeholder={getTranslation({group: "verified-document", id: "message"})} value={_message} onChange={(e) => setMessage(e.target.value)}/>
      <div className='actions' style={{marginTop: 15}}>
        <Button onClick={hideModal}><IntlText id="cancel" /></Button>
        <Button theme="main" onClick={save}><IntlText id="save" /></Button>
      </div>
    </Modalv3>             
  )    
}

function UploadModal({modal, upload}){

  function _upload(e){
    upload(e)
    modal.hide()
  }        

  return(
    <Modalv3 id="upload-document" modal={modal} header={<Title tag="h3"><IntlText group="verified-document" id="edit-message" /></Title>} onHide={() => modal.hide()}>
      <div className='drop-box'>
        <span>{<IntlText group="file-uploader" id="drop-file" />}</span>
        <label htmlFor="upload-modal-input" className=""></label>
        <input id="upload-modal-input" type="file" onChange={_upload} />
      </div>
    </Modalv3>             
  )    
}