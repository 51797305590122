import React, { useRef, useContext } from 'react'
import { toast } from 'react-toastify'
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import { useModal } from '../../hooks'
import { formatPrice } from '../../helpers'
import { IntlContext } from '../../contexts'
import { GET_DAY_CLOSING_SNAPSHOT, PROCESS_RIDER_MODIFICATION_MUTATION, DELETE_RIDER_MODIFICATION_MUTATION, CREATE_RIDER_MODIFICATION_MUTATION, RIDER_MODIFICATIONS_QUERY } from '../../graphql'
import { Wrapper, Loader, IntlText, Button, Modal, Title, Modalv3 } from '../../components'
import { AddModification, FinishRiderWorkshift, RiderWorkshiftTotal, RiderWorkshiftDetails, RiderWorkshiftList } from '../../views'

export function RiderDayClosing() {

  const { id } = useParams()
  const { getTranslation } = useContext(IntlContext)
  const addModificationModal = useModal()
  const closeDayModal = useRef()
  const [createRiderModification, { loading: createLoading }] = useMutation(CREATE_RIDER_MODIFICATION_MUTATION)  
  const [processModification] = useMutation(PROCESS_RIDER_MODIFICATION_MUTATION)
  const [deleteModification] = useMutation(DELETE_RIDER_MODIFICATION_MUTATION)
  const { data, loading, error, refetch, networkStatus } = useQuery(GET_DAY_CLOSING_SNAPSHOT, {variables: {workShiftId: id}, notifyOnNetworkStatusChange: true})
  
  async function _createRiderModification({reason, note, value}){

    try {
      await createRiderModification({variables: {data: {rider: data?.getDayClosingSnapshot?.rider.originalId, note, value: parseInt(value), reason: reason.id}}})
      refetch()
      addModificationModal?.hide()
      return toast.success(getTranslation({id: "add-success"}))
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) e.graphQLErrors.map(x => toast.error(x.message))
      console.log("error", e)
    }
  }

  async function _processModification({id, status}){
    try {
      await processModification({variables: {id, status}})
      refetch()
    } catch (error) {
      console.log("Error", error)
    }
  }

  async function _deleteModification(id){
    try {
      await deleteModification({variables: {id}})
      refetch()
    } catch (error) {
      console.log("Error", error)
    }
  }

  async function refresh(){
    await refetch()
  }

  if (loading && networkStatus !== 4) return <Loader theme="main" />
  if (error) return `Error! ${error.message}`

  const { getDayClosingSnapshot } = data

  return(
    <Wrapper>
      <div id="day-closing" className="container">
        <div className="row padding">
          <RiderWorkshiftTotal data={getDayClosingSnapshot}>
            <Button onClick={() => closeDayModal.current?.show()} theme="approve"><IntlText group="day-closing" id="collect" /></Button>
            <Button loading={loading} onClick={refresh}><IntlText id="refresh" /></Button>
          </RiderWorkshiftTotal>
          <RiderWorkshiftDetails data={getDayClosingSnapshot} />
          <RiderWorkshiftList.List id="modifications" title={<IntlText group="day-closing" id="modifications" />} opened={getDayClosingSnapshot.modifications.length > 0}>     
            <div className='scroller'>
              {getDayClosingSnapshot.modifications.length > 0 ? getDayClosingSnapshot.modifications.map((modification, key) => 
                <RiderWorkshiftList.ListItem key={key}>
                  <span>{modification.title}</span>
                  <span className='amount' style={modification.value > 0 ? {color: "var(--valid-color)"} : {color: "var(--invalid-color)"}}>{formatPrice(modification.value)}</span>
                  <span style={{marginLeft: "auto"}} className='actions'>
                    {modification.status === "PENDING" ?
                      <>
                        <Button theme="approve" icon="check" onClick={() => _processModification({id: modification.id, status: "APPROVED"})} />
                        <Button theme="reject" icon="times" onClick={() => _processModification({id: modification.id, status: "REJECTED"})} />
                      </>
                    :  
                      <Button theme="reject" icon="trash" onClick={() => _deleteModification(modification.id)} />
                    }
                  </span>
                </RiderWorkshiftList.ListItem>
              ):
                <div className='no-items'><IntlText id="nothing-found" /></div>
              }
            </div>
            <Button fluid onClick={addModificationModal?.show}><IntlText group="day-closing" id="add-modification" /></Button>
          </RiderWorkshiftList.List>
          <RiderWorkshiftList.List id="modifications-history" title={<IntlText group="day-closing" id="modifications-history" />}>     
            <div className='scroller'>
              <ModificationsHistory rider={data?.getDayClosingSnapshot?.rider.originalId} />
            </div>
          </RiderWorkshiftList.List>
          <RiderWorkshiftList.List id="tips" title={<IntlText group="day-closing" id="tips" />}>     
            {getDayClosingSnapshot.tips.length > 0 ? getDayClosingSnapshot.tips.map((tip, key) => 
              <RiderWorkshiftList.ListItem key={key}>
                <span>{tip.title}</span>
                <span className='amount' style={{color: "var(--invalid-color)"}}>{formatPrice(tip.value)}</span>
              </RiderWorkshiftList.ListItem>
            ):
            <div className='no-items'><IntlText id="nothing-found" /></div>
            }
          </RiderWorkshiftList.List>
          <RiderWorkshiftList.List id="revenue" title={<IntlText group="day-closing" id="revenue" />}>     
            {getDayClosingSnapshot.revenue.length > 0 ? getDayClosingSnapshot.revenue.map((revenue, key) => 
              <RiderWorkshiftList.ListItem key={key}>
                <span>{revenue.title}</span>
                <span className='amount' style={{color: "var(--valid-color)"}}>{formatPrice(revenue.riderCollectible)}</span>
              </RiderWorkshiftList.ListItem>
            ):
              <div className='no-items'><IntlText id="nothing-found" /></div>
            }
          </RiderWorkshiftList.List>
        </div>
      </div>
      <Modalv3 modal={addModificationModal} header={<Title tag="h3"><IntlText group="day-closing" id="add-modification" /></Title>} >
          <AddModification submit={_createRiderModification} loading={createLoading} cancel={addModificationModal?.hide} />
      </Modalv3>      
      <Modal ref={closeDayModal} header={<Title tag="h3"><IntlText group="day-closing" id="close-day" /></Title>}>
        {() =>
          <FinishRiderWorkshift data={getDayClosingSnapshot} cancel={() => closeDayModal.current?.hide()} />
        }
      </Modal>
    </Wrapper>
  )
}

function ModificationsHistory({rider}){

  const {loading, error, data} = useQuery(RIDER_MODIFICATIONS_QUERY, {variables: {rider, reason: ["GAS_SELFSERVE", "NETWORK_AMIGOSINLIMITE"]}})

  if (loading || error) return <Loader theme="main" />

  if (data?.riderModifications.length > 0) return data?.riderModifications.map((modification, key) => 
    <RiderWorkshiftList.ListItem key={key}>
      <span><IntlText group="rider-modifications" id={modification.reason} /></span>
      <span className='date'>{moment(modification.createdAt).format("YYYY-MM-DD")}</span>
      <span className='amount' style={modification.value > 0 ? {color: "var(--valid-color)"} : {color: "var(--invalid-color)"}}>{formatPrice(modification.value)}</span>
    </RiderWorkshiftList.ListItem>
  )
    
  return <div className='no-items'><IntlText id="nothing-found" /></div>
}