import React from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { Loader, LabeledCheckbox, Form } from '../../../components'
import { SETTINGS_QUERY, EDIT_SETTING_MUTATION } from '../../../graphql'
import { extractSettings } from '../../../helpers'

export function PaymentSettings() {

  const { loading, data, error } = useQuery(SETTINGS_QUERY, {variables: {keys: ["CARD_STRIPE_PAYMENTS_AVAILABLE"]}})
  const [_editSetting, { loading: editLoading }] = useMutation(EDIT_SETTING_MUTATION)

  async function editSetting(key, value){
    try {
      await _editSetting({variables: {key, data: {value}}})
    } catch (e) {
      console.log("error: ", e);
    }
  }

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  let settings = extractSettings(data.settings)

  return(
    <div id="operation-settings">
      <Form.Form>
        <Form.Field>
          <LabeledCheckbox loading={editLoading} id="card_stripe_payments_available" placeholder={settings["card_stripe_payments_available"]?.description} rounded checked={settings["card_stripe_payments_available"]?.value} onChange={(e) => editSetting(settings["card_stripe_payments_available"]?.key, e.target.checked.toString())}/>
        </Form.Field>
      </Form.Form>
    </div>
  )
}
