import React from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { SEND_CALL_TO_WORK_NOTIFICATION, EDIT_SETTING_MUTATION, SETTING_QUERY } from '../../../graphql'
import { toast } from '../../../helpers'
import { Button, Modalv3, Title, IntlText, Loader, SwitchBox } from '../../../components'
import { useAuth, useIntl, useModal } from '../../../hooks'

export function DispatcherActions(){

  const modal = useModal()
  const { user } = useAuth()
  const { getTranslation } = useIntl()
  const { loading, data, error } = useQuery(SETTING_QUERY, {variables: {key: "QUEUEISCLOSED"}})
  const [sendCallToWorkNotificationToContractorRiders] = useMutation(SEND_CALL_TO_WORK_NOTIFICATION)
  const [editSetting] = useMutation(EDIT_SETTING_MUTATION)


  async function _editSetting(key, value){
    try {
      await editSetting({variables: {key, data: {value}}})
    } catch (e) {
      console.log("error: ", e);
    }
  }  

  async function _sendCallToWorkNotificationToContractorRiders(){
    const alert = toast.loading()
    
    try {
      await sendCallToWorkNotificationToContractorRiders()
      alert.success(getTranslation({id: "send-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }

  if (loading || error) return <Loader />
  if (user?.superAdmin !== true) return null

  const { setting } = data

  return(
    <div className='dispatcher-actions'>
      <Button icon="bars" onClick={modal.show}><IntlText group="dispatcher-actions" id="title" /></Button>
      <Modalv3 id="dispatcher-actions" modal={modal} header={<Title tag="h3"><IntlText group="dispatcher-actions" id="title" /></Title>} onHide={() => modal.hide()}>
        <SwitchBox id="queueisclosed" label={getTranslation({group: "settings", id: "queueisclosed-info"})} checked={setting?.value === "true"} onChange={({target}) => _editSetting(setting?.key, target.checked.toString())}>
          <IntlText group='settings' id="queueisclosed" />
        </SwitchBox>            
        <Button fluid wrap onClick={_sendCallToWorkNotificationToContractorRiders}><IntlText group="dispatcher-actions" id="sendCallToWorkNotificationToContractorRiders" /></Button>
      </Modalv3>             
    </div>
  )
  
}
  