import React, { useContext } from 'react'
import Modal from 'react-modal'

import { ModalContext } from '../../../contexts'
import { Title, CloseButton } from '../../../components'
import { PurchaseNotes } from '../../../views'

import style from '../../../style/modal'

export default function PurchaseNotesModal() {

  const { isOpened, closeModal, attributes } = useContext(ModalContext)

  if (isOpened("purchaseNotes")) return(
    <Modal isOpen={isOpened("purchaseNotes")} style={style} onRequestClose={() => closeModal()} appElement={document.getElementById('root')}>
      <div id="purchase-notes-modal" className="modal">
        <CloseButton onClick={() => closeModal()}/>
        <div className="modal-info">
          <div className="modal-header">
            <Title tag="h2">Purchase notes</Title>
          </div>
          <div className="modal-content">
            <PurchaseNotes purchase={attributes?.purchase?.id} cancel={closeModal}/>
          </div>
        </div>
      </div>
    </Modal>
  )

  return null
}
