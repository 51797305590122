import React from 'react'
import { useMutation } from '@apollo/client'

import { EDIT_VENDOR_MUTATION } from '../../../graphql'
import { useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { Form, IntlText, SwitchBox } from '../../../components'

export function VendorSettings({vendor}) {

  const { getTranslation } = useIntl()
  const [editVendor] = useMutation(EDIT_VENDOR_MUTATION)

  async function changeSwitch(data){
    const alert = toast.loading()
    try {
      await editVendor({variables: {id: vendor.id, data: data}})
      alert.success(getTranslation({id: "change-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  if (!vendor) return null

  return(
    <div id="vendor-settings">
      <Form.Form>
        <Form.Field>
          <SwitchBox id="slow" label={<IntlText group="vendor-settings" id="slow-preparation-desc" />} checked={vendor.slow} onChange={(e) => changeSwitch({[e.target.id]: e.target.checked})}>
            <IntlText group='vendor-settings' id='slow-preparation' />
          </SwitchBox>              
        </Form.Field>        
        <Form.Field>
          <SwitchBox id="technicalBreak" label={<IntlText group="vendor-settings" id="technical-break-desc" />} checked={vendor.technicalBreak} onChange={(e) => changeSwitch({[e.target.id]: e.target.checked})}>
            <IntlText group='vendor-settings' id='technical-break' />
          </SwitchBox>              
        </Form.Field>
        <Form.Field>
          <SwitchBox id="suspended" label={<IntlText group="vendor-settings" id="suspended-desc" />} checked={vendor.suspended} onChange={(e) => changeSwitch({[e.target.id]: e.target.checked})}>
            <IntlText group='vendor-settings' id='suspended' />
          </SwitchBox>              
        </Form.Field>
        <Form.Field>
          <SwitchBox id="hidden" label={<IntlText group="vendor-settings" id="hidden-desc" />} checked={vendor.hidden} onChange={(e) => changeSwitch({[e.target.id]: e.target.checked})}>
            <IntlText group='vendor-settings' id='hidden' />
          </SwitchBox>              
        </Form.Field>
        <Form.Field>
          <SwitchBox id="onVacation" label={<IntlText group="vendor-settings" id="on-vacation-desc" />} checked={vendor.onVacation} onChange={(e) => changeSwitch({[e.target.id]: e.target.checked})}>
            <IntlText group='vendor-settings' id='on-vacation' />
          </SwitchBox>              
        </Form.Field>
      </Form.Form>
    </div>
  )
}
