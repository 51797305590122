import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import { EDIT_CUSTOMER_MUTATION, MANUALLY_VERIFY_CUSTOMER_CEL } from '../../../graphql'
import { useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { LabeledInput, Button, PhoneNumberInput, Form } from '../../../components'
import { CustomerVerificationBadge } from '../../../views'

export function CustomerDetails({customer}) {

  const { getTranslation } = useIntl()
  const [edited, toggleEdit] = useState(false)
  const [loading, toggleLoading] = useState(false)
  const [editCustomer] = useMutation(EDIT_CUSTOMER_MUTATION)
  const [verifyCustomerCel] = useMutation(MANUALLY_VERIFY_CUSTOMER_CEL)
  const [form, setValues] = useState({name: customer.name, email: customer.email, phone: customer.phone})
  const { name, email, phone } = form

  function updateField(field){
    setValues({
      ...form,
      ...field
    })
    toggleEdit(true)
  }


  async function _verifyCustomerCel(){
    const alert = toast.loading()
    const cel =  customer.cel || customer.phone

    try {
      await verifyCustomerCel({variables: {cel: {
        phoneCode: cel?.phoneCode,
        countryCode: cel?.countryCode,
        phoneNumber: cel?.phoneNumber
      }}})
      alert.success(getTranslation({id: "save-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  async function save (){
    toggleLoading(true)
    let data = {}

    if (name !== customer.name) data.name = name
    if (email !== customer.email) data.email = email
    if (phone !== customer.phone) data.phone = phone

    if (data.name === "" || data.email === "" || data.phone === ""){
      toggleLoading(false)
      return toast.error("Missing data")
    }

    try {
      await editCustomer({variables: {id: customer.id, data}})
      toggleLoading(false)
      return toast.success("Successfully saved")
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
      toggleLoading(false)
    }
  }

  function changePhoneNumberField(data){
    let { countryCode, phoneCode, phoneNumber } = data

    let _phone = {
      countryCode: countryCode,
      phoneCode: phoneCode,
      phoneNumber: phoneNumber
    }

    updateField({phone: _phone})
  }

  return(
    <div id="customer-details">
      <Form.Form>
        <Form.Field>
          <LabeledInput name="name" placeholder="Name" value={name} onChange={(e) => updateField({[e.target.name]: e.target.value})}/>
        </Form.Field>
        <Form.Field>
          <LabeledInput name="email" placeholder="Email" value={email} onChange={(e) => updateField({[e.target.name]: e.target.value})}/>
        </Form.Field>
        <Form.Field style={{display: "flex", alignItems: "center", gap: 10}}>
          <PhoneNumberInput name="phone" placeholder="Phone" value={phone} defaultCountry="mx" featuredCountries={["us", "ca", "mx"]} onChange={(data) => changePhoneNumberField(data)}/>
          <CustomerVerificationBadge customer={customer} />
          {(customer?.celVerificationStatus === "UNVERIFIED" || !customer?.celVerificationStatus) && 
            <Button icon="check" onClick={_verifyCustomerCel}>Verify number</Button>
          }
        </Form.Field>
        <Form.Field>
          {edited && <Button type="button" loading={loading} theme="main"  className="full" onClick={() => save()}>Save</Button>}
        </Form.Field>
      </Form.Form>
    </div>
  )
}
