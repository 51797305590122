import React, { useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { EDIT_ANNOUNCEMENT, ANNOUNCEMENT_QUERY, UPLOAD_ANNOUNCEMENT_MEDIA } from '../../graphql'
import { useValidatedForm } from '../../hooks'
import { toast } from '../../helpers'
import { IntlContext } from '../../contexts'
import { HtmlEditor, LabeledInput, Loader, Wrapper, Form, LabeledTextarea, SwitchBox, IntlText, ImageCropper } from '../../components'
import { AnnouncementOptions, PageHeader } from '../../views'

export function Announcement(){

  const { id } = useParams()
  const { loading, data, error } = useQuery(ANNOUNCEMENT_QUERY, {variables: {id}})

  if (loading || error) return <Loader theme="main" />

  return(
    <Wrapper>
      <div id="announcement" className="view">
        <Inner data={data?.announcement} />
      </div>
    </Wrapper>
  )
}

function Inner({data}){

  const { id, language } = useParams()
  const { getTranslation } = useContext(IntlContext)
  const _language = language || "en"
  const { form, updateField, updateMultilangField, getEditedData } = useValidatedForm({...data})
  const [edit, { loading }] = useMutation(EDIT_ANNOUNCEMENT)
  const [upload, {loading: uploading}] = useMutation(UPLOAD_ANNOUNCEMENT_MEDIA)

  async function _edit(){
    const alert = toast.loading()
    try {
      await edit({variables: {id, data: getEditedData()}})
      alert.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  async function _upload(data){
    const alert = toast.loading()
    try {
      await upload({variables: {announcement: id, ...data}})
      alert.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  async function toggleActive(active){
    const alert = toast.loading()
    try {
      edit({variables: {id, data: {active}}})
      alert.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  return(
    <div className="row padding">
      <PageHeader slug={form.slug} title={data.title} onChange={({target}) => updateField({key: target.name, value: target.value})} />
      <div className="grids">
        <div className="grid">
          <Form.Form>
            <Form.Field>
              <LabeledInput name="title" placeholder="Page title" value={form.title?.[_language]} onChange={({target}) => updateMultilangField({key: target.name, value: target.value, language: _language})} />
            </Form.Field>
            <Form.Field>
              <LabeledTextarea id={`editor-${_language}`} name="description" placeholder="Page description" value={form.description?.[_language]} onChange={({target}) => updateMultilangField({key: target.name, value: target.value, language: _language})}/>
            </Form.Field>
            <Form.Field>
              <HtmlEditor id={`editor-${_language}`} name="content" placeholder="Page content" value={form.content?.[_language]} onChange={({text, target}) => updateMultilangField({key: target.name, value: text, language: _language})}/>
            </Form.Field>
            <Form.Field>
              <ImageCropper id="cover" aspect={16/9} title={getTranslation({group: "announcement", id: "cover"})} placeholder={<IntlText group="announcement" id="cover" />} image={data.cover} uploadImage={(file) => _upload({type: "IMAGE_ANNOUNCEMENT_COVER", file})} uploading={uploading} thumbnailStyle={{width: 178}} />          
            </Form.Field>
          </Form.Form>
        </div>
        <div className="grid sidebar">
          <AnnouncementOptions language={_language} page={data} save={_edit} loading={loading} url={`/announcements/${id}`}>
            <SwitchBox id="active" label={<IntlText id="active-desc" />} checked={data.active} onChange={({target}) => toggleActive(target.checked)}>
              <IntlText id='active' />
            </SwitchBox>        
          </AnnouncementOptions>
        </div>
      </div>
    </div>
  )
}
