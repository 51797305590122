import React from 'react'
import { toast } from 'react-toastify'
import FontAwesome from 'react-fontawesome'
import { useMutation } from '@apollo/client'

import { Form, FileUploader  } from '../../../components'
import { noThumb } from '../../../assets'
import { UPLOAD_VENDOR_MEDIA_MUTATION, DELETE_VENDOR_MEDIA_MUTATION } from '../../../graphql'

export function VendorMedia({vendor}) {

  const [uploadMedia] = useMutation(UPLOAD_VENDOR_MEDIA_MUTATION)
  const [deleteMedia] = useMutation(DELETE_VENDOR_MEDIA_MUTATION)

  async function _uploadMedia({target, type}){
    if (!target.files) return toast.error("There is nothing to upload")

    let file = target.files[0]

    try {
      await uploadMedia({variables: {vendor: vendor.id, file, type}})
      return toast.success("Successfull upload")
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function _deleteMedia(type){
   try {
     await deleteMedia({variables: {id: vendor.id, type}})
     return toast.success("Successfull delete")
   } catch (e) {
     e.graphQLErrors.map(x => toast.error(x.message))
   }
 }

  return(
    <div id="vendor-media">
      <Form.Form>
        <Form.Field>
          <div className="image-uploader">
            <div id="logo" className="preview">
              <img src={vendor.logo || noThumb} alt={vendor.name} title={vendor.name} />
            </div>
            <FileUploader id="logo" placeholder="Logo" onChange={({target}) => _uploadMedia({target, type: "IMAGE_VENDOR_LOGO", width: 300, height: 300})}/>
          </div>
        </Form.Field>
        <Form.Field>
          <div className="image-uploader">
            <div id="cover" className="preview">
              {vendor.cover &&
                <div className="delete"><FontAwesome name="trash" onClick={() => _deleteMedia("IMAGE_VENDOR_COVER")}/></div>
              }
              <img src={vendor.cover || noThumb} alt={vendor.name} title={vendor.name} />
            </div>
            <FileUploader id="cover" placeholder="Cover Mobile" onChange={({target}) => _uploadMedia({target, type: "IMAGE_VENDOR_COVER", width: 1200, height: 460})}/>
          </div>
        </Form.Field>
        <Form.Field>
          <div className="image-uploader wide">
            <div id="wallpaper" className="preview">
              {vendor.wallpaper &&
                <div className="delete"><FontAwesome name="trash" onClick={() => _deleteMedia("IMAGE_VENDOR_WALLPAPER")}/></div>
              }
              <img src={vendor.wallpaper || noThumb} alt={vendor.name} title={vendor.name} />
            </div>
            <FileUploader id="wallpaper" placeholder="Cover PC" onChange={({target}) => _uploadMedia({target, type: "IMAGE_VENDOR_WALLPAPER", width: 1920, height: 260})}/>
          </div>
        </Form.Field>
      </Form.Form>
    </div>
  )

}
