import { useMutation } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'

import { CREATE_CHANGELOG } from '../../graphql'
import { toast } from '../../helpers'
import { useIntl, useValidatedForm } from '../../hooks'
import { HtmlEditor, LabeledInput, Wrapper, Form, LabeledTextarea, IntlText, SelectV2 } from '../../components'
import { PageOptions } from '../../views'

export function NewChangelog(){

  const navigate = useNavigate()
  const { language } = useParams()
  const { getTranslation } = useIntl()
  const _language = language || "en"
  const { form, updateField, updateMultilangField, getEditedData } = useValidatedForm({})
  const [create] = useMutation(CREATE_CHANGELOG)
  const types = ["CUSTOMER_WEB", "CUSTOMER_MOBILE", "CUSTOMER_MOBILE_IOS", "CUSTOMER_MOBILE_ANDROID", "VENDOR_WEB", "VENDOR_MOBILE"]
  const selectedType = types.find(_type => _type === form.type)

  async function _create(){
    const alert = toast.loading()
    try {
      await create({variables: {data: getEditedData()}})
      navigate("/changelogs")
      alert.success(getTranslation({id: "create-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  return(
    <Wrapper>
      <div id="new-changelog" className="view">
        <div className="row padding">
          <div className="grids">
            <div className="grid">
              <Form.Form>
                <Form.Field>
                  <SelectV2 value={(_value) => getTranslation({group: "changelogs", id: _value})} placeholder={getTranslation({group: "changelogs", id: "type"})} selected={selectedType} defaultValue={getTranslation({id: "please-select"})} options={types} onChange={(_type) => updateField({key: "type", value:  _type})} />
                </Form.Field>
                <Form.Field>
                  <LabeledInput name="title" placeholder="Page title" value={form.title?.[_language]} onChange={({target}) => updateMultilangField({key: target.name, value: target.value, language: _language})} />
                </Form.Field>
                <Form.Field>
                  <LabeledTextarea id={`editor-${_language}`} name="description" placeholder="Page description" value={form.description?.[_language]} onChange={({target}) => updateMultilangField({key: target.name, value: target.value, language: _language})}/>
                </Form.Field>
                <Form.Field>
                  <HtmlEditor id={`editor-${_language}`} name="content" placeholder="Page content" value={form.content?.[_language]} onChange={({text, target}) => updateMultilangField({key: target.name, value: text, language: _language})}/>
                </Form.Field>
              </Form.Form>
            </div>
            <div className="grid sidebar">
              <PageOptions placeholder={<IntlText id="create" />} language={_language} save={_create} url={`/changelog/new`} />
            </div>
          </div>
        </div>       
      </div>
    </Wrapper>
  )
}
