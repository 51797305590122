import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import FontAwesome from 'react-fontawesome'

import { useDetectOutsideClick, useFitInView } from '../../hooks'
import { Button as ButtonDefault, Link as LinkDefault, MediaQuery } from '../'

import './style.css'

function Menu({id, loading, placeholder, children, size, type, ...rest}){

  const wrapper = useRef(null)
  const menu = useRef(null)
  const [isActive, setIsActive] = useDetectOutsideClick({wrapper, menu, initialState: false})

  useFitInView({wrapper, element: menu, left: ({element}) => {
    element.current.style.bottom = `0`
    element.current.style.transform = `translate(0,calc(100% + 5px))`
  }, bottom: ({element}) => {
    element.current.style.bottom = `0`
    element.current.style.top = `auto`
  }})

  return(
    <div id={id} className={`context-menu ${size}`} ref={wrapper} {...rest}>
      <MediaQuery max={900}>
        <ButtonDefault icon="bars" loading={loading} className={type} onClick={() => setIsActive(!isActive)}>{placeholder && <span className="placeholder">{placeholder}</span>}</ButtonDefault>
        {isActive && ReactDOM.createPortal(
          <div className="portal-modal">
            <div className='modal' style={{height: "auto"}}>
              <div className="inner" style={{padding: 0}}>
                <div className="modal-content" style={{marginBottom: 0}}>
                  <ul className={`context-menu-dropdown`} style={{position: "relative", opacity: 1, visibility: "visible", transform: "none"}} ref={menu}>
                    {children}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        , document.getElementById('modal-portal'))}
      </MediaQuery>
      <MediaQuery min={900}>    
          <ButtonDefault icon="bars" loading={loading} className={type} onClick={() => setIsActive(!isActive)}>{placeholder && <span className="placeholder">{placeholder}</span>}</ButtonDefault>
          <ul className={`context-menu-dropdown ${isActive ? "opened" : ""}`} ref={menu}>
            {children}
          </ul>
      </MediaQuery>
    </div>  
  )

}

function Link({children, className = "", ...rest}){

  return(
    <li className={className}>
      <LinkDefault {...rest}>{children}</LinkDefault>
    </li>
  )
}

function Button({icon, children, theme, className = "", onClick, ...rest}){

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }

  return(
    <li className={`${className} ${theme}`}>
      <button type='button' className="nav-button" onClick={onClick} {...rest}>
        {icon && showIcon()}
        <span>{children}</span>
      </button>
    </li>
  )
}

const context = { Menu, Link, Button }

export default context
