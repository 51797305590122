import React, { useContext } from 'react'
import Modal from 'react-modal'
import { Query } from '@apollo/client/react/components'

import { ModalContext } from '../../contexts'
import { Title, CloseButton, Loader } from '../../components'
import { PurchaseStatus } from '../../views'
import { PURCHASE_QUERY } from '../../graphql'

import style from '../../style/modal'

export default function PurchaseStatusModal() {

  const { isOpened, closeModal, attributes } = useContext(ModalContext)
  let id = attributes?.purchase?.id

  if (!id) return null

  if (isOpened("purchaseStatus")) return(
    <Modal isOpen={isOpened("purchaseStatus")} style={style} onRequestClose={() => closeModal()} appElement={document.getElementById('root')}>
      <Query query={PURCHASE_QUERY} variables={{id: id}}>
        {({ loading, error, data }) => {
          if (loading) return <Loader theme="main"/>
          if (error) return `Error! ${error.message}`

          const { purchase } = data

          return(
            <div id="purchase-notes-modal" className="modal">
              <CloseButton onClick={() => closeModal()}/>
              <div className="modal-info">
                <div className="modal-header">
                  <Title tag="h2">Purchase notes</Title>
                </div>
                <div className="modal-content">
                  <PurchaseStatus purchase={purchase} />
                </div>
              </div>
            </div>
          )
        }}
      </Query>
    </Modal>
  )

  return null
}
