import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { SETTINGS_QUERY, SETTING_UPDATE_SUBSCRIPTION } from '../../../graphql'
import { extractSettings } from '../../../helpers'
import { Loader } from '../../../components'

export function OperationInfo(){
  
    const { loading, data, error, subscribeToMore } = useQuery(SETTINGS_QUERY, {variables: {keys: ["QUEUEISCLOSED", "rainbreak", "EXCHANGE_RATE_USDMXN"]}})
  
    useEffect(() => {
      const unsub = subscribeToMore({
        document: SETTING_UPDATE_SUBSCRIPTION,
        variables: {settings: ["QUEUEISCLOSED", "rainbreak", "EXCHANGE_RATE_USDMXN"]},
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev
          const _data = [...prev?.settings]
          const index = _data.findIndex((_item) => _item.id === subscriptionData.data.settingUpdated.id)
          if (index >= 0){
            _data[index] = subscriptionData.data.settingUpdated
            return Object.assign({}, prev, {
              settings: _data
            })
          } else {
            return Object.assign({}, prev, {
              settings: [subscriptionData.data.settingUpdated, ..._data]
            })
          }
        }
      })

      return () => {
        unsub()
      }

    // eslint-disable-next-line
    }, [])    
  
    if (loading || error) return <Loader />
  
    const settings = extractSettings(data?.settings)
    const isClosed = !!settings?.queueisclosed?.value || settings?.rainbreak?.value
  
    return(
      <div className="dispatcher-infos">
        <div className='btn'>
          <span className="flag-icon flag-icon-us"></span>
          <span className="value">{settings?.exchange_rate_usdmxn?.value} MXN</span>
        </div>
        {isClosed ? 
          <div className='invalid btn'>
            <FontAwesome name="clock" />
            <span className="label">Closed</span>
          </div>
        :
          <div className='valid btn'>
            <FontAwesome name="clock" />
            <span className="label">Open</span>
          </div>        
        }
      </div>
    ) 
  
  }
  