import React from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import config from '../../../config'
import { Loader, Table, IntlValue, Map, Tooltip, IntlText } from '../../../components'
import { AUDITLOG_PURCHASE } from '../../../graphql'

import './style.css'

export function PurchaseAudit({purchase}){

  const { loading, data, error } = useQuery(AUDITLOG_PURCHASE, {variables: {purchase: purchase?.id, type: "PURCHASE"}, skip: !purchase?.id})

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  const { auditlogsOfPurchase } = data

  return(
    <div id="purchase-audit">
      <div className="map" style={{height: 400}}>
        <Map.Map center={{lng: config.defaultCenter.lng, lat: config.defaultCenter.lat}}>
          {purchase.statusChangeRiderLocations?.RIDERCONFIRM && 
            <Map.Pin lng={purchase.statusChangeRiderLocations?.RIDERCONFIRM.location.coordinates[0]} lat={purchase.statusChangeRiderLocations?.RIDERCONFIRM.location.coordinates[1]}>
              <Tooltip text={<div><IntlText group="purchase-audit" id="RIDERCONFIRM" /> {moment(purchase.statusChangeRiderLocations?.RIDERCONFIRM.timestamp).format("HH:mm")}</div>}><FontAwesome name="map-marker" /></Tooltip>
            </Map.Pin>
          }
          {purchase.statusChangeRiderLocations?.ARRIVEDATVENDOR && 
            <Map.Pin lng={purchase.statusChangeRiderLocations?.ARRIVEDATVENDOR.location.coordinates[0]} lat={purchase.statusChangeRiderLocations?.ARRIVEDATVENDOR.location.coordinates[1]}>
              <Tooltip text={<div><IntlText group="purchase-audit" id="ARRIVEDATVENDOR" /> {moment(purchase.statusChangeRiderLocations?.ARRIVEDATVENDOR.timestamp).format("HH:mm")}</div>}><FontAwesome name="map-marker" /></Tooltip>
            </Map.Pin>
          }
          {purchase.statusChangeRiderLocations?.ONTHEWAY && 
            <Map.Pin lng={purchase.statusChangeRiderLocations?.ONTHEWAY.location.coordinates[0]} lat={purchase.statusChangeRiderLocations?.ONTHEWAY.location.coordinates[1]}>
              <Tooltip text={<div><IntlText group="purchase-audit" id="ONTHEWAY" /> {moment(purchase.statusChangeRiderLocations?.ONTHEWAY.timestamp).format("HH:mm")}</div>}><FontAwesome name="map-marker" /></Tooltip>
            </Map.Pin>
          }
          {purchase.statusChangeRiderLocations?.ATLOCATION && 
            <Map.Pin lng={purchase.statusChangeRiderLocations?.ATLOCATION.location.coordinates[0]} lat={purchase.statusChangeRiderLocations?.ATLOCATION.location.coordinates[1]}>
              <Tooltip text={<div><IntlText group="purchase-audit" id="ATLOCATION" /> {moment(purchase.statusChangeRiderLocations?.ATLOCATION.timestamp).format("HH:mm")}</div>}><FontAwesome name="map-marker" /></Tooltip>
            </Map.Pin>
          }
          {purchase.statusChangeRiderLocations?.COMPLETED && 
            <Map.Pin lng={purchase.statusChangeRiderLocations?.COMPLETED.location.coordinates[0]} lat={purchase.statusChangeRiderLocations?.COMPLETED.location.coordinates[1]}>
              <Tooltip text={<div><IntlText group="purchase-audit" id="COMPLETED" /> {moment(purchase.statusChangeRiderLocations?.COMPLETED.timestamp).format("HH:mm")}</div>}><FontAwesome name="map-marker" /></Tooltip>
            </Map.Pin>
          }
        </Map.Map>
      </div>
      <Table.Table withScroller>
        <Table.Head>
          <Table.Tr>
            <Table.Th className="text-left">Date</Table.Th>
            <Table.Th className="text-left">Description</Table.Th>
            <Table.Th className="text-left">Meta</Table.Th>
          </Table.Tr>
        </Table.Head>
        <Table.Body>
          {auditlogsOfPurchase.length > 0 ? auditlogsOfPurchase.map((da, key) =>
            <Table.Tr key={key}>
              <Table.Td className="text-left">{moment(da.createdAt).format("YYYY-MM-DD HH:mm")}</Table.Td>
              <Table.Td className="text-left"><IntlValue value={da.message} /></Table.Td>
              <Table.Td className="text-left">{da.metadata.join(" ")}</Table.Td>
            </Table.Tr>
          ) :
            <Table.Tr>
              <Table.Td colSpan={3}>There is nothing here, try to search</Table.Td>
            </Table.Tr>
          }
        </Table.Body>
      </Table.Table>
    </div>
  )
}
