import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'

import { toast } from '../../../helpers'
import { EDIT_RIDER_MUTATION, UPLOAD_RIDER_TAX_DOC, UPLOAD_RIDER_TAX_CERTIFICATES } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { SelectV2, Form, IntlText, EditBox, Notificaion, ContextMenu } from '../../../components'
import { VerifiedPhoto, VerifiedDocument, RiderTaxCertificatesDocument } from '../../../views'

export function RiderDetails({rider}){

  const { getTranslation } = useContext(IntlContext)
  const [editRider] = useMutation(EDIT_RIDER_MUTATION)
  const [uploadRiderTaxDoc] = useMutation(UPLOAD_RIDER_TAX_DOC)
  const [uploadRiderTaxCertificates] = useMutation(UPLOAD_RIDER_TAX_CERTIFICATES)
  const ranks = ["TEST", "BEGINNER", "ADVANCED", "PROFESSIONAL", "ELITE"]

  async function save(_data){
    const { success, error } = toast.loading()

    try {
      await editRider({variables: {id: rider.id, data: _data}})
      return success(getTranslation({id: "edit-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => error(x.message))
    }
  }

  async function _uploadRiderTaxDoc({target}){
    const { success, error } = toast.loading()

    try {
      if (!target.files) return toast.error("There is nothing to upload")

      let file = target.files[0]

      await uploadRiderTaxDoc({variables: {file, id: rider.id}})
      return success(getTranslation({id: "edit-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => error(x.message))
    }
  }

  async function _uploadRiderTaxCertificates({files, password}){
    const { success, error } = toast.loading()

    try {
      if (!files) return toast.error("There is nothing to upload")

      await uploadRiderTaxCertificates({variables: {files, id: rider.id, password}})
      return success(getTranslation({id: "edit-success"}))
    } catch (e) {
      error(e.message)
      e.graphQLErrors.map(x => error(x.message))
    }
  }

  function getStatus(){
    switch (rider.profileStatus) {
      case "INREVISION":
      case "INREVIEW":
        return "warning"
      case "APPROVED":
        return "success"
      case "BANNED":
        return "error"
      default:
        return "success"
    }
  }

  function getIcon(){
    switch (rider.profileStatus) {
      case "INREVISION":
      case "INREVIEW":
        return "clock"
      case "APPROVED":
        return "check"
      case "BANNED":
        return "times"
      default:
        return "check"
    }    
  }  

  return(
    <div id="rider-details">
      <Form.Form>
        <Form.Field>
          <Notificaion title={<IntlText id={rider?.profileStatus} />} icon={getIcon()} type={getStatus()} actions={
            <ContextMenu.Menu id={rider?.id}>
              <ContextMenu.Button className='valid' onClick={() => save({profileStatus: "APPROVED"})} icon="check"><IntlText id="APPROVED" /></ContextMenu.Button>
              <ContextMenu.Button className='warning' onClick={() => save({profileStatus: "INREVIEW"})} icon="clock"><IntlText id="INREVIEW" /></ContextMenu.Button>
              <ContextMenu.Button className='warning' onClick={() => save({profileStatus: "INREVISION"})} icon="clock"><IntlText id="INREVISION" /></ContextMenu.Button>
              <ContextMenu.Button className='invalid' onClick={() => save({profileStatus: "BANNED"})} icon="ban"><IntlText id="BANNED" /></ContextMenu.Button>
            </ContextMenu.Menu>       
          }>
            <span><IntlText group="rider-form" id={`${rider?.profileStatus}-info`} /></span>     
          </Notificaion>          
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<IntlText group="rider-form" id="full-name" />} save={(value) => save({fullName: value})}>{rider.fullName}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<IntlText group="rider-form" id="name" />} save={(value) => save({name: value})}>{rider.name}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<IntlText group="rider-form" id="username" />} disabled>{rider.username}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <EditBox.Date placeholder={<IntlText group="rider-form" id="birthdate" />} save={(value) => save({birthdate: value})}>{rider.birthdate}</EditBox.Date>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<IntlText group="rider-form" id="type" />} disabled>{getTranslation({group: "rider-form", id: rider.type})}</EditBox.Text>
        </Form.Field>        
        <Form.Field>
          <SelectV2 value={(_value) => <IntlText group="rider-form" id={_value} />} placeholder={<IntlText group="rider-form" id="rank" />} selected={ranks?.find(_t => _t === rider.rank)} options={ranks} onChange={(_rank) => save({rank: _rank})} />
        </Form.Field>
        <Form.Field>
          <VerifiedPhoto name={getTranslation({group: "rider-form", id:"profile-pic"})} url={rider?.riderProfilePic} status={rider?.riderProfilePicStatus} accept={() => save({riderProfilePicStatus: "ACCEPTED"})} reject={(message) => save({riderProfilePicStatus: "REJECTED", riderProfilePicMessage: message})} message={rider?.riderProfilePicMessage} editMessage={(_val) => save({riderProfilePicMessage: _val})} />
        </Form.Field>
        <Form.Field>
          <VerifiedDocument type="image" name={getTranslation({group: "rider-form", id:"identification"})} url={rider?.identification} status={rider?.identificationStatus} expiry={rider?.identificationExpiry} edit={(_val) => save({identificationExpiry: _val})} accept={() => save({identificationStatus: "ACCEPTED"})} reject={(message) => save({identificationStatus: "REJECTED", identificationMessage: message})} message={rider?.identificationMessage} editMessage={(_val) => save({identificationMessage: _val})} />
        </Form.Field>
        <Form.Field>
          <VerifiedDocument type="image" name={getTranslation({group: "rider-form", id:"moto-license"})} url={rider?.motoLicense}  status={rider?.motoLicenseStatus} expiry={rider?.motoLicenseExpiry} edit={(_val) => save({motoLicenseExpiry: _val})} accept={() => save({motoLicenseStatus: "ACCEPTED"})} reject={(message) => save({motoLicenseStatus: "REJECTED", motoLicenseMessage: message})} message={rider?.motoLicenseMessage} editMessage={(_val) => save({motoLicenseMessage: _val})} images={[{title: "License back", src: rider?.motoLicenseBack}]} />
        </Form.Field>
        <Form.Field>
          <VerifiedDocument type="image" name={getTranslation({group: "rider-form", id:"auto-license"})} url={rider?.autoLicense}  status={rider?.autoLicenseStatus} expiry={rider?.autoLicenseExpiry} edit={(_val) => save({autoLicenseExpiry: _val})} accept={() => save({autoLicenseStatus: "ACCEPTED"})} reject={(message) => save({autoLicenseStatus: "REJECTED", autoLicenseMessage: message})} message={rider?.autoLicenseMessage} editMessage={(_val) => save({autoLicenseMessage: _val})} images={[{title: "License back", src: rider?.autoLicenseBack}]} />
        </Form.Field>
        <Form.Field>
          <VerifiedDocument name={getTranslation({group: "rider-form", id:"tax-doc"})} url={rider?.taxDoc}  status={rider?.taxDocStatus} expiry={rider?.taxDocExpiry} edit={(_val) => save({taxDocExpiry: _val})} accept={() => save({taxDocStatus: "ACCEPTED"})} reject={(message) => save({taxDocStatus: "REJECTED", taxDocMessage: message})} message={rider?.taxDocMessage} editMessage={(_val) => save({taxDocMessage: _val})} upload={_uploadRiderTaxDoc} />
        </Form.Field>
        <Form.Field>
          <RiderTaxCertificatesDocument name={getTranslation({group: "rider-form", id:"tax-certificates"})} status={rider?.taxCertificatesStatus} expiry={rider?.taxCertificatesExpiry} edit={(_val) => save({taxCertificatesExpiry: _val})} accept={() => save({taxCertificatesStatus: "ACCEPTED"})} reject={(message) => save({taxCertificatesStatus: "REJECTED", taxCertificatesMessage: message})} message={rider?.taxCertificatesMessage} editMessage={(_val) => save({taxCertificatesMessage: _val})} upload={_uploadRiderTaxCertificates} />
        </Form.Field>
      </Form.Form>
    </div>
  )

}
