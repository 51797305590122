import React from 'react'
import { useQuery } from '@apollo/client'

import { VENDOR_QUERY } from '../../../../graphql'
import { useModal } from '../../../../hooks'
import { Link, ContextMenu, Modalv3, Title, IntlText, Loader } from '../../../../components'
import { VendorSettings } from '../../../../views'

export function VendorField({purchase}) {

  const modal = useModal()

  return(
    <div className="vendor-field">
      <Link to={`/vendors/${purchase.vendor.originalId}`}><span className="link">{purchase.vendor.name}</span></Link>
      <ContextMenu.Menu id={purchase?.id}>
        {purchase.vendor?.whatsappGroup &&
          <ContextMenu.Button onClick={() => window.open(purchase.vendor?.whatsappGroup)} icon={<i className="fab fa-whatsapp" />}>Send what's app</ContextMenu.Button>
        }
        <ContextMenu.Button onClick={modal.show} icon="cog">Settings</ContextMenu.Button>
      </ContextMenu.Menu>  
      <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="vendor" id="tab-menu-settings" /></Title>}>
        <SettingsModal modal={modal} vendor={purchase.vendor.originalId} />
      </Modalv3>     
    </div>
  )

}

function SettingsModal({vendor}){
  const { loading, data, error } = useQuery(VENDOR_QUERY, {variables: {id: vendor}})

  if (loading || error) return <Loader theme='main' />

  return(
    <VendorSettings vendor={data?.vendor} />
  )
}
