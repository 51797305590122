import React, { useContext, useState, useEffect } from 'react'

import { Title, LabeledCheckbox, HourPicker, IntlValue } from '../../../components'
import { IntlContext } from '../../../contexts'

import './style.css'

const days = [{en: "Monday", es: "Lunes"}, {en: "Tuesday", es: "Martes"}, {en: "Wednesday", es: "Miercoles"}, {en: "Thursday", es: "Jueves"}, {en: "Friday", es: "Viernes"}, {en: "Saturday", es: "Sabado"}, {en: "Sunday", es: "Domingo"}]

export function OpeningHours({openingHours = [{isOpen: false}, {isOpen: false}, {isOpen: false}, {isOpen: false}, {isOpen: false}, {isOpen: false}, {isOpen: false}], onChange, loading}) {

  const { getTranslation } = useContext(IntlContext)
  const [allDay, toggleAllDay] = useState(openingHours.every((oph) => oph?.isOpen))

  useEffect(() => {
    toggleAllDay(openingHours.every((oph) => oph?.isOpen))
  }, [openingHours])

  function changeAvailibility(day, value){
    openingHours[day].isOpen = value
    openingHours.forEach(openingHour => delete openingHour["__typename"])

    onChange({openingHours})
  }

  function changeTime(day, key, data){
    openingHours[day][key] = (data.hours + data.mins).toString()
    openingHours.forEach(openingHour => delete openingHour["__typename"])

    onChange({openingHours})
  }

  function changeAllAvailibility(value){
    days.forEach((item, i) => {
      openingHours[i].isOpen = value
    })
    openingHours.forEach(openingHour => delete openingHour["__typename"])
    toggleAllDay(value)
    onChange({openingHours})
  }

  function changeAllTime({key, data}){
    days.forEach((item, i) => {
      openingHours[i][key] = (data.hours + data.mins).toString()
    })
    openingHours.forEach(openingHour => delete openingHour["__typename"])

    onChange({openingHours})
  }

  return(
    <div id="opening-hours">
      <div className="day">
        <div className="day-title">
          <LabeledCheckbox loading={loading} rounded id={`open-all`} checked={allDay} onChange={(e) => changeAllAvailibility(e.target.checked)}/>
          <Title tag="h3">Every day</Title>
        </div>
        {allDay &&
          <div className="hours-wrap">
            <HourPicker time={openingHours[0]?.from || "0000"} placeholder={getTranslation({group: "opening-hours", id: "from"})} onChange={(data) => changeAllTime({key: "from", data})}/>
            <HourPicker time={openingHours[0]?.to || "0000"} placeholder={getTranslation({group: "opening-hours", id: "to"})} onChange={(data) => changeAllTime({key: "to", data})}/>
          </div>
        }
      </div>
      {openingHours.map((day, key) =>
        <div key={key} className="day">
          <div className="day-title">
            <LabeledCheckbox loading={loading} rounded id={`open-${key}`} checked={day.isOpen} onChange={(e) => changeAvailibility(key, e.target.checked)}/>
            <Title tag="h3"><IntlValue>{days[key]}</IntlValue></Title>
          </div>
          {day.isOpen && !allDay &&
            <div className="hours-wrap">
              <HourPicker time={day.from || "0000"} placeholder={getTranslation({group: "opening-hours", id: "from"})} onChange={(data) => changeTime(key, "from", data)}/>
              <HourPicker time={day.to || "0000"} placeholder={getTranslation({group: "opening-hours", id: "to"})} onChange={(data) => changeTime(key, "to", data)}/>
            </div>
          }
        </div>
      )}
    </div>
  )
}
