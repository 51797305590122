import { useQuery, useMutation } from '@apollo/client'
import moment from 'moment'

import { PURCHASE_QUOTES, DELETE_PURCHASE_QUOTE } from '../../graphql'
import { useIntl, useConfirm } from '../../hooks'
import { toast } from '../../helpers'
import { Wrapper, Table, Link, ContextMenu, IntlText } from '../../components'

export function PurchaseQuotes() {

  const { confirm }= useConfirm()
  const { getTranslation } = useIntl()
  const { loading, data } = useQuery(PURCHASE_QUOTES)
  const [deletePurchaseQuote] = useMutation(DELETE_PURCHASE_QUOTE)

  async function _deletePurchaseQuote(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await deletePurchaseQuote({variables: {id}})
      alert.success(getTranslation({id: "delete-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }

  return(
    <Wrapper loading={loading}>
      <div id="purchase-quotes" className="view">
        <div className="row padding">
          <Table.Table>
            <Table.Head>
              <Table.Tr>
                <Table.Th>#</Table.Th>
                <Table.Th>Customer</Table.Th>
                <Table.Th>Vendor</Table.Th>
                <Table.Th>Payment</Table.Th>
                <Table.Th>Status</Table.Th>
                <Table.Th>Date</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Head>
            <Table.Body>          
              {data?.purchaseQuotes?.nodes?.map((purchaseQuote) => 
                <Table.Tr key={purchaseQuote.id}>
                  <Table.Td>{purchaseQuote.id}</Table.Td>
                  <Table.Td><Link to={`/customers/${purchaseQuote.customer}`}>{purchaseQuote.customer}</Link></Table.Td>
                  <Table.Td><Link to={`/vendors/${purchaseQuote.vendor}`}>{purchaseQuote.vendor}</Link></Table.Td>
                  <Table.Td>{purchaseQuote.payment}</Table.Td>
                  <Table.Td>{purchaseQuote.status}</Table.Td>
                  <Table.Td>{moment(purchaseQuote.createdAt).format('YYYY-MM-DD HH:mm')}</Table.Td>
                  <Table.Td>
                    <ContextMenu.Menu id={purchaseQuote.id}>
                      <ContextMenu.Button className="invalid" icon="trash" onClick={() => _deletePurchaseQuote(purchaseQuote.id)}><IntlText id="delete" /></ContextMenu.Button>
                    </ContextMenu.Menu>
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Body>          
        </Table.Table>          
        </div>
      </div>
    </Wrapper>
  )
}
