import React from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { OrdersList, HistoryFilter } from '../../views'
import { Loader, Pagination, Wrapper, QueryResults, Placeholder } from '../../components'
import { PURCHASES_PAGINATED_QUERY } from '../../graphql'
import { useQueryBuilder } from '../../hooks'

import './style.css'

export function Orders() {

  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const { params, addParam, removeParam, clearParams, isQuery, buildQuery, runQuery, updateQuery, setPage, page, sort, setSort } = useQueryBuilder({
    data: {
      date: {key: "date", label: `${search.getAll("date")[0]} - ${search.getAll("date")[1]}`, value: search.getAll("date")?.length === 2 ? [search.getAll("date")[0], search.getAll("date")[1]] : undefined, skip: !search.get("date")},
      rider: {key: "rider", label: `${search.get("rider")}`, value: search.get("rider")},
      status: {key: "status", label: `${search.get("status")}`, value: search.get("status")},
      payment: {key: "payment", label: `${search.get("payment")}`, value: search.get("payment")},
      query: {key: "query", label: `Search for ${search.get("query")}`, value: search.get("query")},
      isReferral: {key: "isReferral", label: `Only referral`, value: Boolean(search.get("isReferral"))},
      sort: {key: "payment", label: search.get("sort"), value: search.get("sort")},
    },
    page: search.get("page"),
  }, [{id: "date", validation: (obj) => !!obj?.value, error: "You have to select date"}])

  const { data, loading, error } = useQuery(PURCHASES_PAGINATED_QUERY, {variables: buildQuery(), skip: !isQuery(), notifyOnNetworkStatusChange: true})

  if (error) return `Error! ${error.message}`
  
  return(
    <Wrapper>
      <div id="orders-history" className="view">
        <div className="row padding">
          <HistoryFilter loading={loading} params={params} addParam={addParam} removeParam={removeParam} clearParams={clearParams} sort={sort} setSort={setSort} runQuery={runQuery} updateQuery={updateQuery} />
          <div className="relative">
            {loading && <Loader theme="main" overlay/>}
            <QueryResults pageInfo={data?.purchasesPaginated?.pageInfo} reset={clearParams} />
            {isQuery() ?
              <OrdersList data={data?.purchasesPaginated?.nodes || []} />
            :
              <Placeholder.Search />
            }
            <Pagination page={page} setPage={setPage} pageInfo={data?.purchasesPaginated?.pageInfo}/>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
