import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { Wrapper, IntlText, SlidingMenu, Grids } from '../../components'
import { CUSTOMER_QUERY } from '../../graphql'
import { CustomerReferral, CustomerDetails, CustomerAddresses, CustomerPaymentMethods, CustomerSettings, CustomerBalance, CustomerBalanceHistory, CustomerTagEditor, CustomerOrders, CustomerReset } from '../../views'

export function Customer() {

  const { view, id } = useParams()
  const {loading, error, data} = useQuery(CUSTOMER_QUERY, {variables: {id}})

  if (error) return `Error! ${error.message}`

  return(
    <Wrapper loading={loading}>
      <div id="customer" className="container">
        <div className="row padding">
          <SlidingMenu base={`/customers/${id}/`} menu={[
            {label: <IntlText group="customer" id="tab-menu-details" />, to: ""},
            {label: <IntlText group="customer" id="tab-menu-balance" />, to: "balance"},
            {label: <IntlText group="customer" id="tab-menu-addresses" />, to: "addresses"},
            {label: <IntlText group="customer" id="tab-menu-orders" />, to: "orders"},
            {label: <IntlText group="customer" id="tab-menu-settings" />, to: "settings"},
            {label: <IntlText group="customer" id="tab-menu-payment-methods" />, to: "payment-methods"},
            {label: <IntlText group="customer" id="tab-menu-tags" />, to: "tags"},
            {label: <IntlText group="customer" id="tab-menu-referral" />, to: "referral"},
            {label: <IntlText group="customer" id="tab-menu-reset" />, to: "reset"},
          ]} />
          <Grids.Grids style={{marginTop: 40}}>
            <Grids.Grid>
              {(!view || view === "details") &&
                <CustomerDetails customer={data?.customer} />
              }
              {view === "balance" &&
                <div>
                  <CustomerBalance customer={data?.customer?.id} />
                  <CustomerBalanceHistory customer={data?.customer?.id} />
                </div>
              }
              {view === "settings" &&
                <CustomerSettings customer={data?.customer} />
              }
              {view === "addresses" &&
                <CustomerAddresses customer={data?.customer} />
              }
              {view === "payment-methods" &&
                <CustomerPaymentMethods customer={data?.customer} />
              }
              {view === "referral" &&
                <CustomerReferral customer={data?.customer} />
              }
              {view === "tags" &&
                <CustomerTagEditor customer={data?.customer} />
              }
              {view === "orders" &&
                <div>
                  <CustomerOrders customer={data?.customer} />
                </div>
              }
              {view === "reset" &&
                <CustomerReset customer={data?.customer} />
              }
            </Grids.Grid>
          </Grids.Grids>              
        </div>
      </div>
    </Wrapper>
  )
}
