import { gql }from '@apollo/client'

const PRODUCT_FRAGMENT = gql`
  fragment PRODUCT_FRAGMENT on Product {
    id
    rank
    slug
    category
    owner
    media{
      cover
      thumbnail
    }
    tags
    name{
      en
      es
    }
    description{
      en
      es
    }
    price
    discount
    discountedPrice
    minPurchaseQuantity
    maxPurchaseQuantity
    disabled
    hidden
    addons{
      id
      rank
      options{
        id
        slug
        name{
          en
          es
        }
        price
        disabled
        quantifiable
      }
      addon{
        id
        slug
        owner        
        name{
          en
          es
        }
        label{
          en
          es
        }
        description{
          en
          es
        }
        instruction{
          en
          es
        }
        minSelect
        maxSelect
        required
        disabled
      }
    }
  }
`

export default PRODUCT_FRAGMENT