import React from 'react'
import FontAwesome from 'react-fontawesome'

import { Loader } from '../'

import './style.css'

export function Input({value, icon, reset = () => console.log("Reset not defined"), loading, ...rest}){

    return(
      <div className={`input ${!!value ? "selected" : ""}`}>
        <input autoComplete="off" value={value || ""} {...rest}/>
        {loading && <Loader loading={loading} />}
        {!!value && !loading && <FontAwesome onClick={reset} name="trash" />}
      </div>
    )
  
  }
  